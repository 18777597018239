import React, { useState, useEffect } from 'react';
import './sidemenu.css';
import logo from "../../assets/images/parliament-logo.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { VscDashboard } from "react-icons/vsc";
import { VscCommentDiscussion } from "react-icons/vsc";
import { VscHeart } from "react-icons/vsc";
import { VscExtensions } from "react-icons/vsc";
import { VscGear } from "react-icons/vsc";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import { IoCloseCircle } from "react-icons/io5";
import { HiOutlineStatusOnline } from "react-icons/hi";

import { FiMenu } from "react-icons/fi";
// import Api from '../Api/Api';
function Sidemenu({ setlogged_in, onLogout }) {

    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const user_role = localStorage.getItem('user_role');
    // const { SignOut } = Api();
    const navigate = useNavigate();
    // const handleLogout = () => {
    //     setlogged_in(false);
    //     onLogout();
    //     localStorage.removeItem('auth_token'); 
    //     // localStorage.setItem('logged_in', JSON.stringify(false));
    // }


    const UserRole = localStorage.getItem('user_role');

    const handleLogout = () => {
        debugger;
        setlogged_in(false);
        localStorage.removeItem('auth_token'); // Remove the auth token from localStorage
        localStorage.removeItem('user_id'); 
        localStorage.removeItem('user_role'); 
        localStorage.removeItem('email_id');
        localStorage.removeItem('user_name');
        localStorage.setItem('logged_in', 'false'); 
        localStorage.removeItem('logged_in');
        if (user_role === "ADMIN") {
            navigate('/');
        }
        else {
            navigate('/');
        }
    };

    const closemobiletogglebar = () => {
        setSidebarOpen(!isSidebarOpen);
        if (!isSidebarOpen) {
            document.body.classList.add('sidebar_open');
        } else {
            document.body.classList.remove('sidebar_open');
        }
    };

    return (
        <div>

            <div className="main_sidemenu">
                <div className="sidemenu_logo">
                    <div className="pos-rel">
                        <img src={logo} alt="logo" />
                        <div className="close_sidemenu" onClick={closemobiletogglebar}>
                            {isSidebarOpen ? <IoCloseCircle /> : <FiMenu />}
                        </div>

                    </div>
                </div>
                <div className="list_main_menu">
                    <ul className="main_main_ul_list">
                        {UserRole == 'ADMIN' && (
                            <>
                                <li>
                                    <NavLink to="/admin/dashboard" activeClassName="active" className="page_list">
                                        <div className="inner_page_list">
                                            <div className="main_menu_icon"><VscDashboard /></div>
                                            <div className="main_menu_name" >
                                                Dashboard
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="">
                                    <NavLink to="/admin/meetings" activeClassName="active" className="page_list">
                                        <div className="inner_page_list">
                                            <div className="main_menu_icon"><VscExtensions /></div>
                                            <div className="main_menu_name">
                                                Meetings
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                            </>
                        )}


                        {UserRole == 'USER' && (
                            <>
                                <li className="">
                                    <NavLink to="/user/livevideos" activeClassName="active" className="page_list">
                                        <div className="inner_page_list">
                                            <div className="main_menu_icon"><HiOutlineStatusOnline /></div>
                                            <div className="main_menu_name">Live Videos</div>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="">
                                    <NavLink to="/user/videos" activeClassName="active" className="page_list">
                                        <div className="inner_page_list">
                                            <div className="main_menu_icon"><VscCommentDiscussion /></div>
                                            <div className="main_menu_name">Videos</div>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="">
                                    <NavLink to="/user/likedvideos" activeClassName="active" className="page_list">
                                        <div className="inner_page_list">
                                            <div className="main_menu_icon"><AiOutlineLike /></div>
                                            <div className="main_menu_name">Liked Videos</div>
                                        </div>
                                    </NavLink>
                                </li>
                            </>
                        )}

                        {UserRole == 'ADMIN' && (
                            <>
                                <li className="">
                                    <NavLink to="/admin/settings" activeClassName="active" className="page_list">
                                        <div className="inner_page_list">
                                            <div className="main_menu_icon"><VscGear /></div>
                                            <div className="main_menu_name">Settings</div>
                                        </div>
                                    </NavLink>
                                </li>
                            </>
                        )}

                        <li className="">
                            <div className="page_list">
                                <div className="inner_page_list">
                                    <div className="main_menu_icon text-danger"><AiOutlinePoweroff /></div>
                                    <div className="main_menu_name text-danger" onClick={handleLogout}>Logout</div>
                                </div>
                            </div>
                        </li>



                    </ul>
                </div>


            </div>
        </div>
    )
}

export default Sidemenu
