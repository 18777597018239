import React, { useEffect, useRef, useState } from 'react';
import "./LikedVideos.css"
import { Link } from 'react-router-dom';
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import videoImg from "../../assets/images/video_thum.png";
import Api from '../Api/Api';

function LikedVideos() {

    const [isChecked, setIsChecked] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [FavoriteVideoList, setFavoriteVideoList] = useState([]);
    const [LikedVideoList, setLikedVideoList] = useState([]);
    const [likeCount, setLikeCount] = useState(12);
    const { FavoriteList, LikedList, AddFavoriteVideo, RemoveFavoriteVideo, AddLikeVideo, RemoveLikeVideo } = Api();
    const [likedVideos, setLikedVideos] = useState({});
    const [favoritedVideos, setFavoritedVideos] = useState({});
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleLikeChange = () => {
        setIsLike(!isLike);
        if (!isLike) {
            setLikeCount(likeCount + 1);
        } else {
            setLikeCount(likeCount - 1);
        }
    };

    const GetFavorite = async () => {
        try {
            let data = await FavoriteList();
            // setcatalogData(data.categories);
            console.log("FavoriteList", data.parameters.data);
            setFavoriteVideoList(data.parameters.data)
        } catch (err) {
            console.log(err);
        }
    };


    const GetLiked = async () => {
        try {
            let data = await LikedList();
            // setcatalogData(data.categories);
            console.log("LikedList", data.parameters.data);
            setLikedVideoList(data.parameters.data)
        } catch (err) {
            console.log(err);
        }
    };



      // Update the liked status of a video

      const handleLikeChangee = async ({ videoId, videocheck }) => {
        setLikedVideos(prevState => ({
            ...prevState,
            [videoId]: !prevState[videoId]
        }));
        try {
            if (videocheck === 0) {
                await AddLikeVideo(videoId);
            } else {
                await RemoveLikeVideo(videoId);
            }
        } catch (err) {
            console.log(err);
        }
    };


    // Update the favorited status of a video

    const handleFavoriteChange = async ({ videoId, videocheck }) => {
        setFavoritedVideos(prevState => ({
            ...prevState,
            [videoId]: !prevState[videoId]
        }));

        // const isFavorited = !favoritedVideos[videoId]; 
        debugger
        try {
            if (videocheck === 0) {
                await AddFavoriteVideo(videoId);
                // handleFilterChange();
                // console.log("wk");
            } else {
                await RemoveFavoriteVideo(videoId);
                // handleFilterChange();
            }
        } catch (err) {
            console.log(err);
        }
    };



    useEffect(() => {
        GetFavorite();
        GetLiked();
    }, []);
    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className='d-flex align-items-center justify-content-between liked_videos-title'>
                            <div className="page_title pb-0">
                                Liked Videos
                            </div>
                            <div>
                                <ul className="nav nav-tabs border-0 liked_videos-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-bs-toggle="tab" href="#LikedVideos">Liked Videos</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#Favourites">Favourites</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="row">
                    <div className="col-12">
                        <div className="tab-content">
                            <div id="LikedVideos" className="tab-pane active">
                                <div className="row mt-3">
                                    {LikedVideoList && LikedVideoList.map((video, index) => (
                                        <>
                                            <div className="col-12 col-sm-6 col-md-4 mb-3 mob_padding">
                                                <div className="video_blog-card">
                                                    <div className="video_banner mb-3 position-relative">
                                                        <img src={videoImg} alt="video banner" />
                                                        <label className="like_video" htmlFor={video.id + 'Favorite'}>
                                                            <input
                                                                id={video.id + 'Favorite'}
                                                                type="checkbox"
                                                                checked={video.is_users_favorite === 1}
                                                                onChange={() => handleFavoriteChange({ videoId: video.id, videocheck: video.is_users_favorite })}
                                                            />
                                                            {video.is_users_favorite === 1 ? <IoMdHeart /> : <IoMdHeartEmpty />}
                                                        </label>
                                                        <label className="thump_like" htmlFor={video.id + 'Like'}>
                                                            <input
                                                                id={video.id + 'Like'}
                                                                type="checkbox"
                                                                checked={likedVideos[video.id]}
                                                                onChange={() => handleLikeChangee({ videoId: video.id, videocheck: video.is_user_liked })}
                                                            />
                                                            {video.is_user_liked === 1 ? <AiFillLike className='like_icon' /> : <AiOutlineLike className='like_icon' />}
                                                            <span className='like_count'>{video.total_likes}</span>
                                                        </label>
                                                    </div>
                                                    <div className="video_description mb-3">
                                                    <Link to={`/user/meeting-detail/${video.id}`} className='text-decoration-none'>
                                                        <div className="video_title">{video.video_title}</div>
                                                        <div className="video_duration">2 Hours 15 Mins ({video.from_time} - {video.to_time})</div>
                                                        </Link>
                                                    </div>
                                                    <div className="video_other d-flex justify-content-between align-items-center">
                                                        <div className="video_date">Date : {video.video_date}</div>
                                                        <div className='video_social-media'>
                                                            <ul className='action_list'>
                                                                <li>
                                                                    <Link to={video.youtube_link} target='_blank'>
                                                                        <img src={playIcon} alt="play" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={video.facebook_link} target='_blank'>
                                                                        <img src={fbIcon} alt="facebook" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={video.twitter_link} target='_blank'>
                                                                        <img src={twitterIcon} alt="twitter" />
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>

                            </div>
                            <div id="Favourites" className="tab-pane fade">
                                <div className="row mt-3">

                                    {FavoriteVideoList && FavoriteVideoList.map((video, index) => (
                                        <>
                                            <div className="col-12 col-sm-6 col-md-4 mb-3 mob_padding" key={video.id}>
                                                <div className="video_blog-card">
                                                    <div className="video_banner mb-3 position-relative">
                                                        <img src={videoImg} alt="video banner" />
                                                        <div className="like_video">
                                                            <input type="checkbox" checked={isChecked} />
                                                            <IoMdHeart />
                                                        </div>
                                                        <div className="thump_like ">
                                                            <input type="checkbox" checked={isLike} />
                                                            <AiFillLike className='like_icon' />
                                                            <span className='like_count'>{video.total_likes}</span>
                                                        </div>
                                                    </div>
                                                    <div className="video_description mb-3">
                                                    <Link to={`/user/meeting-detail/${video.id}`} className='text-decoration-none'>
                                                        <div className="video_title">{video.video_title}</div>
                                                        <div className="video_duration">2 Hours 15 Mins (10:15 AM - 12:30 PM)</div>
                                                        </Link>
                                                    </div>
                                                    <div className="video_other d-flex justify-content-between align-items-center">
                                                        <div className="video_date">Date : {video.video_date}</div>
                                                        <div className='video_social-media'>
                                                            <ul className='action_list'>
                                                                <li>
                                                                    <Link to={video.youtube_link} target='_blank'>
                                                                        <img src={playIcon} alt="play" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={video.facebook_link} target='_blank'>
                                                                        <img src={fbIcon} alt="facebook" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={video.twitter_link} target='_blank'>
                                                                        <img src={twitterIcon} alt="twitter" />
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default LikedVideos
