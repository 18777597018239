import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
// import 'datatables.net-bs4';
// import '../../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { Link } from 'react-router-dom';
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import { CiSearch } from "react-icons/ci";
import Api from '../Api/Api';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
function MeetingList() {
    const dataTableRef = useRef(null);
    const { TitleMeetingCount } = Api();
    const [MeetingData, setMeetingData] = useState([]);


    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = MeetingData.slice(indexOfFirstRow, indexOfLastRow);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleVisibility = () => {
        setIsOpen(!isOpen);
    };

    const handleNextPage = async () => {
        try {
            setCurrentPage(prevPage => prevPage + 1);
        } catch (error) {
            console.error("Error occurred while fetching next page:", error);
        }
    };

    const handlePrevPage = async () => {
        try {
            setCurrentPage(prevPage => prevPage - 1);
        } catch (error) {
            console.error("Error occurred while fetching previous page:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await handleFilterChange();
            } catch (error) {
                console.error("Error occurred while fetching data:", error);
            }
        };
        fetchData();
    }, [currentPage]);


    // const GetMeetingList = async () => {
    //     try {
    //         let data = await TitleMeetingCount();
    //         setMeetingData(data.parameters.data);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };



    const handleFilterChange = async () => {
        try {
            const meetTitle = document.getElementsByName('title')[0].value;
            setLoading(true);
            const filters = {
                title: meetTitle,
                page: currentPage.toString()
            };
            const response = await TitleMeetingCount(filters);
            setMeetingData(response.parameters.data);
            setLoading(false);
            console.log("filters", response.parameters.data);
            // Handle response here
        } catch (error) {
            setLoading(false);
            console.error("Error occurred during register:", error);
        }
    };

    const handleResetFilters = () => {
        document.getElementsByName('title')[0].value = "";
        handleFilterChange();
    };

    useEffect(() => {
        // GetMeetingList();
        handleFilterChange();
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="mob_fil_align">
                        <div className="page_title title_wise">
                            Meeting Title wise Statistics
                        </div>
                        <div className="mobile_filter_icon mob_fills_icon" onClick={toggleVisibility}>
                            <img src={filter_icon} alt="filter" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row desktop_filter align-items-center mb-3 mt-2">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className={`filter_container ${isOpen ? 'open' : ''}`}>
                        <div className="filter_icon filter_col sm_flex-end" onClick={toggleVisibility}>
                            <img src={filter_icon} alt="filter" className='hide_mobile' />
                            <IoClose className='show_mobile filter_close' />
                        </div>
                        <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
                            Search
                        </div>
                        <form className='d-flex align-items-center meeting_form'>
                            <div className="status_filter filter_col">
                                <div className="name_filter position-relative">
                                    <input type="text" placeholder='Title' name='title' onChange={handleFilterChange} />
                                    <span><CiSearch /></span>
                                </div>
                            </div>

                            <div className="white-space-nowrap reset_filter filter_col d-flex align-items-center justify-content-center" onClick={handleResetFilters}>
                                <img src={reload_icon} alt="reset" className='me-2' />  Reset Filter
                            </div>
                        </form>
                    </div>
                </div>

            </div>



            <div className="table-responsive main_datatable">
                <table ref={dataTableRef} className="table custom_datatable" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>TITLE</th>
                            <th className="text-center">COUNT</th>
                        </tr>
                    </thead>


                    {loading ? (
                        // Loader component or loading indicator
                        <tbody>
                            <tr>
                                <td colSpan="3" className="text-center">Loading...</td>
                            </tr>
                        </tbody>
                    ) : (
                        // Your regular UI content
                        <tbody>

                            {MeetingData && MeetingData.length ? (
                                MeetingData.map((meet, index) => (
                                    <tr key={meet.id}>
                                        <td className='text-center'>{indexOfFirstRow + index + 1}</td>
                                        <td>{meet.video_title}</td>
                                        <td className="text-center">{meet.view_count}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center">No data</td>
                                </tr>
                            )}
                        </tbody>
                    )}






                </table>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <p className='m-0 table_page-count'>Showing {indexOfFirstRow + 1}-{Math.min(indexOfLastRow, MeetingData.length)} of {MeetingData.length}</p>
                <div className='d-flex align-items-center justify-content-end table_nav'>
                    <button
                        disabled={currentPage === 1}
                        onClick={handlePrevPage}
                    >
                        <FaAngleLeft />
                    </button>
                    <button
                        // disabled={indexOfLastRow >= MeetingData.length} 
                        disabled={MeetingData.length === 0}
                        onClick={handleNextPage}
                    >
                        <FaAngleRight />
                    </button>
                </div>

            </div>
        </div>
    )
}

export default MeetingList
