import React, { useEffect, useRef, useState } from 'react';
import "./videos.css";
import 'bootstrap-datepicker';
import $ from 'jquery';
import '../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import videoImg from "../../assets/images/video_thum.png";
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import Api from '../Api/Api';

import { Link } from 'react-router-dom';
function VideoBlog() {


    const [isVisible, setIsVisible] = useState(false);
    const [VideoData, setVideoData] = useState([]);
    const { MeetingList, AddFavoriteVideo, RemoveFavoriteVideo, AddLikeVideo, RemoveLikeVideo } = Api();
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    const handleClose = () => {
        setIsVisible(false);
    };



    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [clickvideos, setvideos] = useState(false);
    console.log(clickvideos);
    const [likeCount, setLikeCount] = useState(12);// video.likeCount
    const [checkedState, setCheckedState] = useState({});
    useEffect(() => {
        $(fromDateRef.current).datepicker();
        $(toDateRef.current).datepicker();
    }, []);



    const handleCheckboxChange = (videoId) => {
        setCheckedState(prevState => ({
            ...prevState,
            [videoId]: !prevState[videoId] // Toggle the checked state for the given videoId
        }));
    };

    const handleLikeChange = (videoId) => {
        setLikeCount(prevState => ({
            ...prevState,
            [videoId]: (prevState[videoId]) // Increment like count for the given videoId
        }));
    };


    const [likedVideos, setLikedVideos] = useState({});
    const [favoritedVideos, setFavoritedVideos] = useState({});
    console.log(favoritedVideos);





    // Update the liked status of a video

    const handleLikeChangee = async ({ videoId, videocheck }) => {
        setLikedVideos(prevState => ({
            ...prevState,
            [videoId]: !prevState[videoId]
        }));
        try {
            if (videocheck === 0) {
                await AddLikeVideo(videoId);
                handleFilterChange();
                // console.log("wk");
            } else {
                await RemoveLikeVideo(videoId);
                handleFilterChange();
            }
        } catch (err) {
            console.log(err);
        }
    };


    // Update the favorited status of a video

    const handleFavoriteChange = async ({ videoId, videocheck }) => {
        setFavoritedVideos(prevState => ({
            ...prevState,
            [videoId]: !prevState[videoId]
        }));

        // const isFavorited = !favoritedVideos[videoId]; 
        debugger
        try {
            if (videocheck === 0) {
                await AddFavoriteVideo(videoId);
                handleFilterChange();
                // console.log("wk");
            } else {
                await RemoveFavoriteVideo(videoId);
                handleFilterChange();
            }
        } catch (err) {
            console.log(err);
        }
    };



    const handleFilterChange = async () => {
        try {
            const filters = {
                status: document.getElementById('status').value,
                fromDate: fromDateRef.current.value,
                toDate: toDateRef.current.value,
                fromTime: document.getElementsByName('FromTime')[0].value,
                toTime: document.getElementsByName('ToTime')[0].value
            };

            const response = await MeetingList(filters);
            console.log("MeetingList--data", response.parameters.data);
            setVideoData(response.parameters.data);
            // Handle response here
        } catch (error) {
            console.error("Error occurred during register:", error);
        }
    };

    useEffect(() => {
        $(fromDateRef.current).datepicker();
        $(toDateRef.current).datepicker();
        // GetMeetings();
        handleFilterChange();
    }, []);




    useEffect(() => {
        // GetVideos();
        // handleFavoriteChange();
    }, []);
    const handleResetFilters = () => {
        fromDateRef.current.value = "";
        toDateRef.current.value = "";
        document.getElementById('status').value = "";
        document.getElementsByName('FromTime')[0].value = "";
        document.getElementsByName('ToTime')[0].value = "";
        handleFilterChange();
    };



    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mob_fil_align">
                            <div className="page_title">Videos</div>
                            <div className="mobile_filter_icon mob_fills_icon" onClick={toggleVisibility}>
                                <img src={filter_icon} alt="filter" />
                            </div>
                        </div>

                    </div>
                </div>


                <div className="row desktop_filter">
                    <div className="col-12">
                        <form>
                            <div className="filter_container">
                                <div className="filter_icon filter_col">
                                    <img src={filter_icon} alt="filter" />
                                </div>
                                <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
                                    Filter By
                                </div>
                                <div className="status_filter filter_col">
                                    <select className="form-control" id="status" name="status" onChange={handleFilterChange}>
                                        <option value="">Status</option>
                                        <option value="Upcoming">Upcoming</option>
                                        <option value="Completed">Completed</option>
                                        <option value="In-progress">In-progress</option>
                                    </select>
                                </div>
                                <div className="from_date-filter filter_col">
                                    <input
                                        ref={fromDateRef}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="From Date"
                                        name='FromDate'
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="to_date-filter filter_col">
                                    <input
                                        ref={toDateRef}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="To Date"
                                        name='ToDate'
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="from_time-filter filter_col">
                                    <input type="time" name='FromTime' className="form-control" onChange={handleFilterChange} />
                                </div>
                                <div className="to_time-filter filter_col">
                                    <input type="time" name='ToTime' className="form-control" onChange={handleFilterChange} />
                                </div>
                                <div className="reset_filter filter_col d-flex align-items-center justify-content-center" onClick={handleResetFilters}>
                                    <img src={reload_icon} alt="reset" className="me-2" /> Reset Filter
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <ul className="nav nav-tabs videos_tab justify-content-center border-0" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#live">Live</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#adjourned">Adjourned</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#upcoming">Upcoming</a>
                            </li>
                        </ul>


                        <div className="tab-content mt-4">
                            <div id="live" className="container tab-pane active">
                                <div className="row">
                                    {VideoData && VideoData.map((video, index) => (
                                        <>
                                            <div className="col-12 col-sm-6 col-md-4 mb-3 mob_padding" key={video.id}>

                                                <div className="video_blog-card">
                                                    <div className="video_banner mb-3 position-relative">
                                                        <img src={video.cover_link || videoImg} alt={video.video_title} />
                                                        <label className="like_video" htmlFor={video.id + 'Favorite'}>
                                                            <input
                                                                id={video.id + 'Favorite'}
                                                                type="checkbox"
                                                                checked={video.is_users_favorite === 1}
                                                                onChange={() => handleFavoriteChange({ videoId: video.id, videocheck: video.is_users_favorite })}
                                                            />
                                                            {video.is_users_favorite === 1 ? <IoMdHeart /> : <IoMdHeartEmpty />}
                                                        </label>
                                                        <label className="thump_like" htmlFor={video.id + 'Like'}>
                                                            <input
                                                                id={video.id + 'Like'}
                                                                type="checkbox"
                                                                checked={likedVideos[video.id]}
                                                                onChange={() => handleLikeChangee({ videoId: video.id, videocheck: video.is_user_liked })}
                                                            />
                                                            {video.is_user_liked === 1 ? <AiFillLike className='like_icon' /> : <AiOutlineLike className='like_icon' />}
                                                            <span className='like_count'>{video.total_likes}</span>
                                                        </label>
                                                    </div>
                                                    <div className="video_description mb-3">
                                                        <Link to={`/user/meeting-detail/${video.id}`} className='text-decoration-none'>
                                                            <div className="video_title">{video.video_title}</div>
                                                            <div className="video_duration">2 Hours 15 Mins ({video.from_time.substring(0, 5)} - {video.to_time.substring(0, 5)})</div>
                                                        </Link>
                                                    </div>
                                                    <div className="video_other d-flex justify-content-between align-items-center">
                                                        <div className="video_date">Date : {video.video_date}</div>
                                                        <div className='video_social-media'>
                                                            <ul className='action_list'>
                                                                <li>
                                                                    <Link to={video.youtube_link} target='_blank'>
                                                                        <img src={playIcon} alt="play" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={video.facebook_link} target='_blank'>
                                                                        <img src={fbIcon} alt="facebook" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={video.twitter_link} target='_blank'>
                                                                        <img src={twitterIcon} alt="twitter" />
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    ))}

                                </div>

                            </div>
                            <div id="adjourned" className="container tab-pane fade">
                                <div className="col-12 col-sm-6 col-md-4 mb-3 mob_padding">
                                    <div className="video_blog-card">
                                        <div className="video_banner mb-3 position-relative">
                                            <img src={videoImg} alt="video banner" />
                                            <div className="like_video">
                                                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                                                {isChecked ? <IoMdHeart /> : <IoMdHeartEmpty />}
                                            </div>
                                            <div className="thump_like ">
                                                <input type="checkbox" checked={isLike} onChange={handleLikeChange} />
                                                {isLike ? <AiFillLike className='like_icon' /> : <AiOutlineLike className='like_icon' />}
                                                <span className='like_count'>{likeCount}</span>
                                            </div>
                                        </div>
                                        <div className="video_description mb-3">
                                            <div className="video_title">Portfolio Committee on Basic</div>
                                            <div className="video_duration">2 Hours 15 Mins (10:15 AM - 12:30 PM)</div>
                                        </div>
                                        <div className="video_other d-flex justify-content-between align-items-center">
                                            <div className="video_date">Date : 24-06-2023</div>
                                            <div className='video_social-media'>
                                                <ul className='action_list'>
                                                    <li>
                                                        <Link href="">
                                                            <img src={playIcon} alt="play" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link href="">
                                                            <img src={fbIcon} alt="facebook" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link href="">
                                                            <img src={twitterIcon} alt="twitter" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="upcoming" className="container tab-pane fade">
                                <div className="col-12 col-sm-6 col-md-4 mb-3 mob_padding">
                                    <div className="video_blog-card">
                                        <div className="video_banner mb-3 position-relative">
                                            <img src={videoImg} alt="video banner" />
                                            <div className="like_video">
                                                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                                                {isChecked ? <IoMdHeart /> : <IoMdHeartEmpty />}
                                            </div>
                                            <div className="thump_like ">
                                                <input type="checkbox" checked={isLike} onChange={handleLikeChange} />
                                                {isLike ? <AiFillLike className='like_icon' /> : <AiOutlineLike className='like_icon' />}
                                                <span className='like_count'>{likeCount}</span>
                                            </div>
                                        </div>
                                        <div className="video_description mb-3">
                                            <div className="video_title">Portfolio Committee on Basic</div>
                                            <div className="video_duration">2 Hours 15 Mins (10:15 AM - 12:30 PM)</div>
                                        </div>
                                        <div className="video_other d-flex justify-content-between align-items-center">
                                            <div className="video_date">Date : 24-06-2023</div>
                                            <div className='video_social-media'>
                                                <ul className='action_list'>
                                                    <li>
                                                        <Link href="">
                                                            <img src={playIcon} alt="play" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link href="">
                                                            <img src={fbIcon} alt="facebook" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link href="">
                                                            <img src={twitterIcon} alt="twitter" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </div>

        </div>
    )
}

export default VideoBlog
