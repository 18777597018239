import React, { useEffect, useRef, useState } from 'react';
import './meetings.css';
import $ from 'jquery';
// import 'datatables.net-bs4';
// import '../../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import 'bootstrap-datepicker';
import '../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import { Link } from 'react-router-dom';
import { LiaTimesSolid } from "react-icons/lia";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

import Api from '../Api/Api';
function Meetings() {


    const [isVisible, setIsVisible] = useState(false);
    const [MeetingData, setMeetingData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = MeetingData.slice(indexOfFirstRow, indexOfLastRow);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const toggleVisibility = () => {
        setIsOpen(!isOpen);
    };


    const handleNextPage = async () => {
        try {
            setCurrentPage(prevPage => prevPage + 1);
        } catch (error) {
            console.error("Error occurred while fetching next page:", error);
        }
    };

    const handlePrevPage = async () => {
        try {
            setCurrentPage(prevPage => prevPage - 1);
        } catch (error) {
            console.error("Error occurred while fetching previous page:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await handleFilterChange();
            } catch (error) {
                console.error("Error occurred while fetching data:", error);
            }
        };
        fetchData();
    }, [currentPage]);


    const { MeetingList } = Api();


    const handleClose = () => {
        setIsVisible(false);
    };

    const dataTableRef = useRef(null);
    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);




    // const GetMeetings = async () => {
    //     try {
    //         let data = await MeetingList();
    //         // setcatalogData(data.categories);
    //         console.log("meeting", data);
    //         setMeetingData(data.parameters.data);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };



    const handleFilterChange = async () => {
        try {
            const statusValue = document.getElementById('status').value;
            setLoading(true);

            if (statusValue !== "") {
                setCurrentPage(1);
            }
            const filters = {
                status: statusValue,
                fromDate: fromDateRef.current.value,
                toDate: toDateRef.current.value,
                fromTime: document.getElementsByName('FromTime')[0].value,
                toTime: document.getElementsByName('ToTime')[0].value,
                page: currentPage.toString()
            };

            const response = await MeetingList(filters);
            console.log("MeetingList--data", response.parameters.data);
            setMeetingData(response.parameters.data);
            setLoading(false);
            // Handle response here
        } catch (error) {
            setLoading(false);
            console.error("Error occurred during filtering meetings:", error);
        }
    };

    useEffect(() => {
        $(fromDateRef.current).datepicker();
        $(toDateRef.current).datepicker();
        // GetMeetings();
        handleFilterChange();
    }, []);


    const handleResetFilters = () => {
        fromDateRef.current.value = "";
        toDateRef.current.value = "";
        document.getElementById('status').value = "";
        document.getElementsByName('FromTime')[0].value = "";
        document.getElementsByName('ToTime')[0].value = "";
        handleFilterChange();
    };

    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mob_fil_align">
                            <div className="page_title">
                                Meetings
                            </div>
                            <div className="mobile_filter_icon mob_fills_icon" onClick={toggleVisibility}>
                                <img src={filter_icon} alt="filter" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row desktop_filter">
                    <div className="col-12">
                        <form>
                            <div className={`filter_container ${isOpen ? 'open' : ''}`}>
                                <div className="filter_icon filter_col sm_flex-end" onClick={toggleVisibility}>
                                    <img src={filter_icon} alt="filter" className='hide_mobile' />
                                    <IoClose className='show_mobile filter_close' />
                                </div>
                                <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
                                    Filter By
                                </div>
                                <div className="status_filter filter_col">
                                    <select className="form-control" id="status" name="status" onChange={handleFilterChange}>
                                        <option value="">Status</option>
                                        <option value="Upcoming">Upcoming</option>
                                        <option value="Completed">Completed</option>
                                        <option value="In-progress">In-progress</option>
                                    </select>
                                </div>
                                <div className="from_date-filter filter_col">
                                    <input
                                        ref={fromDateRef}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="From Date"
                                        name='FromDate'
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="to_date-filter filter_col">
                                    <input
                                        ref={toDateRef}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="To Date"
                                        name='ToDate'
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="from_time-filter filter_col">
                                    <input type="time" name='FromTime' className="form-control" onChange={handleFilterChange} />
                                </div>
                                <div className="to_time-filter filter_col">
                                    <input type="time" name='ToTime' className="form-control" onChange={handleFilterChange} />
                                </div>
                                <div className="reset_filter filter_col d-flex align-items-center justify-content-center" onClick={handleResetFilters}>
                                    <img src={reload_icon} alt="reset" className="me-2" /> Reset Filter
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

                <div className="row mob_desk_margin">
                    <div className="col-12">
                        <div className="table-responsive main_datatable">
                            <table ref={dataTableRef} className="table custom_datatable meeting_table" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th className='text-start'>DATE</th>
                                        <th className='text-start'>TITLE</th>
                                        <th className='text-start'>START TIME</th>
                                        <th className='text-start'>ENDTIME</th>
                                        <th className='text-start'>STATUS</th>
                                        <th className="text-center">ACTION</th>
                                    </tr>
                                </thead>

                                {loading ? (
                                    // Loader component or loading indicator
                                    <tbody>
                                        <tr>
                                            <td colSpan="7" className="text-center">Loading...</td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                        {MeetingData && MeetingData.length ? (
                                            MeetingData.map((meet, index) => (
                                                <tr key={meet.id}>
                                                    <td className='text-center'>{indexOfFirstRow + index + 1}</td>
                                                    <td className='text-start'>{meet.video_date}</td>
                                                    <td className='text-start'>
                                                        <Link to={`/admin/meeting-detail/${meet.id}`}> {meet.video_title}</Link>
                                                    </td>
                                                    <td className='text-center'>{meet.from_time.substring(0, 5)}</td>
                                                    <td className='text-center'>{meet.to_time.substring(0, 5)}</td>
                                                    <td>
                                                        <button className={meet.status === "COMPLETED" ? 'btn custom_btn-success ws-75' : meet.status === "UPCOMING" ? 'btn custom_btn-info ws-75' : 'btn custom_btn-danger ws-75'}>
                                                            {meet.status === "COMPLETED" ? 'Completed' : meet.status === "UPCOMING" ? 'Upcoming' : 'In Progress'}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <ul className='action_list'>
                                                            <li>
                                                                <Link to={meet.youtube_link} target='_blank'>
                                                                    <img src={playIcon} alt="play" />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={meet.facebook_link} target='_blank'>
                                                                    <img src={fbIcon} alt="facebook" />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={meet.twitter_link} target='_blank'>
                                                                    <img src={twitterIcon} alt="twitter" />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">No data</td>
                                            </tr>
                                        )}


                                    </tbody>

                                )}
                            </table>
  
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p className='m-0 table_page-count'>Showing {indexOfFirstRow + 1}-{Math.min(indexOfLastRow, MeetingData.length)} of {MeetingData.length}</p>
                            <div className='d-flex align-items-center justify-content-end table_nav'>
                                {/* <button disabled={currentPage === 1} onClick={handlePrevPage}>
                                    <FaAngleLeft />
                                </button>
                                <button disabled={indexOfLastRow >= MeetingData.length} onClick={handleNextPage}>
                                    <FaAngleRight />
                                </button> */}
                                <button
                                    disabled={currentPage === 1}
                                    onClick={handlePrevPage}
                                >
                                    <FaAngleLeft />
                                </button>
                                <button
                                    // disabled={indexOfLastRow >= MeetingData.length} 
                                    disabled={MeetingData.length === 0}
                                    onClick={handleNextPage}
                                >
                                    <FaAngleRight />
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Meetings
