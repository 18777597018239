
import React, { useState, useEffect } from 'react';
import './register.css';
import '../login/login.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../assets/images/parliament-logo.png";
import bg from "../../assets/images/login-bg.png";
import closeIcon from "../../assets/images/circle-close.svg";
import eyeIcon from "../../assets/images/eye.svg";
import eyeIcon2 from "../../assets/images/slash-eye.svg";
import Api from '../Api/Api';
import { useForm, SubmitHandler } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Register({ setlogged_in }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { SignUp } = Api();
    const navigate = useNavigate();




    const handleChange = (e) => {
        setName(e.target.value);
    };
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const clearName = () => {
        setValue("name", "");
    };
    const clearEmail = () => {
        setValue("email", "");
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const onSubmit = async (data) => {
        debugger;
        try {
            const response = await SignUp(data);
            console.log("Register", data);
            if (response.success) {
                // setlogged_in(true);
                toast.success("Congratulations! Your account has been successfully created.");
                navigate('/login');
                
            } else if(response.parameters.email){
                // Handle login failure
                console.log("Register failed");
                // console.log("response", response.parameters.email[0]);
                // setEmailError(response.parameters[0].email);
                toast.error(response.parameters.email[0]);
            }
            else{
                toast.error(response.parameters.password[0]);
            }
        } catch (error) {
            console.error("Error occurred during register:", error);
        }
    };
    return (
        <div className="main_body mobile_login">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 sm-order-2">
                        <div className="login_left text-center">
                            <div className='login_logo mb-3'>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className='title_part'>Our Meetings are available on <br></br>these Platforms</div>
                            <div className='login_bg mobile_bg'>
                                <img src={bg} alt="login" className="w-85 mobi_bg" />
                            </div>
                            <div className="copy_right d-sm-block d-md-none d-lg-none text-center t_small_none">
                                Copyright 2024. All Rights Reserved
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="form_container">
                            <div className='login_logo-right mb-3'>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className='login_header-menu mb-3 register'>
                                <ul>
                                    {/* <li>
                                        <select class="form-control lang_select p-0">
                                            <option selected>English</option>
                                            <option value="1">French</option>
                                        </select>
                                    </li> */}
                                    <li>
                                        <Link to="/user/login">Sign in</Link>
                                    </li>
                                </ul>
                            </div>


                            <div className="login_form-inner">
                                <div className='login_title'>Create an Account</div>
                                <div className='login_sm-title'>Let’s get started with us !!!</div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='input_from position-relative'>
                                        <input
                                            type="text"
                                            placeholder='Enter Name'
                                            // value={name}
                                            // onChange={handleChange}
                                            name="name"
                                            onChange={handleChange}
                                            {...register("name", { required: "Enter Name" })}
                                        />
                                        {errors.name && <span className='error_message'>
                                            {errors.name.message}</span>}
                                        {name == '' && (
                                            <span className='text_close' onClick={clearName}>
                                                <img src={closeIcon} alt="close" />
                                            </span>
                                        )}
                                    </div>
                                    <div className='input_from position-relative'>
                                        <input
                                            type="email"
                                            placeholder='Enter Email'
                                            // value={email}
                                            name="email"
                                            onChange={handleChangeEmail}
                                            {...register("email", { required: "Email is required" })}
                                        />
                                        {errors.email && <span className='error_message'>
                                            {errors.email.message}</span>}
                                        {email == '' && (
                                            <span className='text_close' onClick={clearEmail}>
                                                <img src={closeIcon} alt="close" />
                                            </span>
                                        )}
                                    </div>
                                    <div className='input_from position-relative'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter Password'
                                            // value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            {...register("password", { required: "Password is required" })}
                                        />
                                        {errors.password && <span className='error_message'>{errors.password.message}</span>}
                                        <span className='text_visible' onClick={togglePasswordVisibility}>
                                            {showPassword ? (
                                                <img src={eyeIcon} alt="hide" />
                                            ) : (
                                                <img src={eyeIcon2} alt="show" />
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <button className='login_submit btn w-100 text-white'>Sign Up</button>
                                    </div>
                                </form>

                                {/* <div className='or_sign text-center position-relative my-4'>
                                    <span className='bg-white d-inline-block px-3'>Or continue with</span>
                                </div> */}

                                <div className='google_btn'>
                                    {/* <button className='btn'>
                                        <img src={google} alt="google" />
                                    </button> */}
                                    <div className='have_login text-center my-4'>
                                        Already have an Account ? <Link to="/user/login">Login</Link>
                                    </div>
                                </div>


                            </div>

                            <div className="copy_right text-center my-4 d-none d-sm-none d-md-block d-lg-block">
                                Copyright 2024. All Rights Reserved
                            </div>

                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
