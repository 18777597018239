import React, { useEffect, useState } from 'react';
import "./header.css";
import { FiMenu } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { Link, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import userIcon from "../../assets/images/profile-img.png";
import { FiUser } from "react-icons/fi";
import Api from '../Api/Api';
import { Line } from 'react-chartjs-2';
function Header() {
    const user_role = localStorage.getItem('user_role');
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const UserName = localStorage.getItem('user_name');
    const [SearchData, setSearchData] = useState([]);
    
    const storedValue = localStorage.getItem('logged_in');
    const [logged_in, setlogged_in] = useState(storedValue);
    const { GlobalSearchList } = Api();
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
        if (!isSidebarOpen) {
            document.body.classList.add('sidebar_open');
        } else {
            document.body.classList.remove('sidebar_open');
        }
    };
    useEffect(() => {
        setlogged_in(storedValue === 'true');
      }, [storedValue]);

    const GetMeetings = async () => {
        try {
            let data = await GlobalSearchList();
            console.log("GetMeetings", data.parameters.data);
            setSearchData(data.parameters.data)
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GetMeetings();
    }, []);


    // const mobiletogglebar = () => {
    //     debugger;
    //     setSidebarOpen(!isSidebarOpen);
    //     if (!isSidebarOpen) {
    //         document.body.classList.add('sidebar_open');
    //     }
    //     else {
    //         document.body.classList.remove('sidebar_open');
    //     }
    // };


    const [searchQuery, setSearchQuery] = useState('');

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filteredData = SearchData.filter(item =>
        item.video_title.toLowerCase().includes(searchQuery.toLowerCase())
    );



    return (
        <div className="header_container">
            <div className="main_header_box">
                <div className="search_with_bar">
                    <div className="sidenav_icon desktop" onClick={toggleSidebar}>
                        <FiMenu />
                    </div>
                    {/* <div className="sidenav_icon mobile" onClick={mobiletogglebar}>
                        <FiMenu />
                    </div> */}
                    <div className="searces_bar position-relative">
                        <div className="name_filters position-relative">
                            <input
                                type="search"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                            <span><CiSearch /></span>
                        </div>
                        {searchQuery !== '' && (
                            <ul>

                                {filteredData.length > 0 ? (
                                    filteredData.map((item, index) => (
                                        <li key={index}>
                                            <Link to={`/user/meeting-detail/${item.id}`}>{item.video_title}</Link>
                                        </li>
                                    ))
                                ) : (
                                    <li className='px-3 no_data'>No data available</li>
                                )

                                }


                            </ul>
                        )}
                    </div>
                </div>
                <div className="">
                    <Dropdown>

                        <Dropdown.Toggle id="dropdown-basic" className="user_button_customs">
                            <img src={userIcon} alt="" className="users_img" />
                            <span className="user_name">{UserName}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {logged_in && (
                                <NavLink
                                    to={user_role === 'ADMIN' ? "/admin/my-profile" : "/user/my-profile"}
                                    className="page_list"
                                >
                                    <div className="inner_pages_list">
                                        <div className="main_menu_icon"><FiUser /></div>
                                        <div className="main_menu_name">Profile</div>
                                    </div>
                                </NavLink>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default Header
