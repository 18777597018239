import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login/Login';
import Register from './components/register/Register';
import ForgotPassword from './components/forgotpassword/ForgotPassword';
import Sidemenu from './components/sidemenu/Sidemenu';
import Dashboard from "./components/dashboard/Dashboard";
import { useEffect, useState } from 'react';
import Header from './components/header/Header';
import Meetings from "./components/meetings/Meetings";
import MeetingDetail from "./components/meetings/meetingDetail";
import "./App.css";
import VideoBlog from './components/videos/VideoBlog';
import MyProfile from './components/profile/MyProfile';
import LiveVideos from './components/LiveVideos/LiveVideos';
import Settings from './components/Settings/Settings';
import AddUser from './components/Settings/AddUser';
import EditUser from './components/Settings/EditUser';
import LikedVideos from './components/LikedVideos/LikedVideos';
import ResetPassword from './components/ResetPassword/ResetPassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const storedValue = localStorage.getItem('logged_in');
  const [logged_in, setlogged_in] = useState(storedValue);
  const user_role = localStorage.getItem('user_role');
  useEffect(() => {
    // toast.success('Login successful! Redirecting to your dashboard...');
    setlogged_in(storedValue === 'true');
  }, [storedValue]);

  // const handleLogout = () => {
  //   setlogged_in(false);
  //   localStorage.setItem('logged_in', JSON.stringify(false));
  // };

  return (
    <div>
      <BrowserRouter>
        {logged_in && <Sidemenu setlogged_in={setlogged_in} />}
        {logged_in && <Header setlogged_in={setlogged_in} />}
        <Routes>
          <Route path="/" element={!logged_in ? <Login setlogged_in={setlogged_in} /> : <Navigate to="/admin/dashboard" />} />
          <Route path="/user/login" element={!logged_in ? <Login setlogged_in={setlogged_in} /> : <Navigate to="/user/livevideos" />} />
          <Route path="/user/register" element={<Register />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/admin/forgot-password" element={<ForgotPassword />} />
          <Route path="/user/forgot-password" element={<ForgotPassword />} />
          <Route path="/admin/dashboard" element={logged_in && user_role === 'ADMIN' ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/admin/meetings" element={logged_in && user_role === 'ADMIN' ? <Meetings /> : <Navigate to="/" />} />
          <Route path="/user/meeting-detail/:videoId" element={logged_in && user_role === 'USER' ? <MeetingDetail /> : <Navigate to="/" />} />
          <Route path="/admin/meeting-detail/:videoId" element={logged_in && user_role === 'ADMIN' ? <MeetingDetail /> : <Navigate to="/" />} />
          <Route path="/user/videos" element={logged_in && user_role === 'USER' ? <VideoBlog /> : <Navigate to="/" />} />
          <Route path="/admin/my-profile" element={logged_in && user_role === 'ADMIN' ? <MyProfile /> : <Navigate to="/" />} />
          <Route path="/user/my-profile" element={logged_in && user_role === 'USER' ? <MyProfile /> : <Navigate to="/" />} />
          <Route path="/user/livevideos" element={logged_in && user_role === 'USER' ? <LiveVideos /> : <Navigate to="/" />} />
          <Route path="/admin/settings" element={logged_in && user_role === 'ADMIN' ? <Settings /> : <Navigate to="/" />} />
          <Route path="/admin/adduser" element={logged_in && user_role === 'ADMIN' ? <AddUser /> : <Navigate to="/" />} />
          <Route path="/user/adduser" element={logged_in && user_role === 'USER' ? <AddUser /> : <Navigate to="/" />} />
          <Route path="/admin/edituser/:user_id" element={logged_in && user_role === 'ADMIN' ? <EditUser /> : <Navigate to="/" />} />
          <Route path="/user/likedvideos" element={logged_in && user_role === 'USER' ? <LikedVideos /> : <Navigate to="/" />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer />


    </div>
  );
}

export default App;
