import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Api from '../Api/Api';
function PolarAreaChart() {
    const { TrendingTopics } = Api();

    const [TrendingData, setTrendingData] = useState([]);


    const GetTrendingData = async () => {
        try {
            let data = await TrendingTopics();
            console.log("TrendingTopics", data);
            setTrendingData(data.parameters);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        GetTrendingData();
    }, []);


    return (
        <div>
            {TrendingData && TrendingData.length > 0 && (
                <Pie
                    data={{
                        labels: TrendingData.map((meet, index) => (
                            [meet.video_title]
                        )),
                        datasets: [
                            {
                                label: 'My First Dataset',
                                boxWidth: 20,

                                // data: [30, 15, 35, 20],

                                data: TrendingData.map((meet, index) => (
                                    [meet.view_percentage]
                                )),
                                backgroundColor: [
                                    '#343C6A',
                                    '#9E7C0C',
                                    '#1814F3',
                                    '#FA00FF',
                                ],
                                borderColor: [
                                    '#343C6A',
                                    '#9E7C0C',
                                    '#1814F3',
                                    '#FA00FF',
                                ],
                                borderWidth: 1,
                            },
                        ]
                    }}
                    options={{
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#fff',
                                formatter: (value, context) => {
                                    return `${value}%`;
                                },
                                anchor: 'end',
                                align: 'start'
                            },
                        },
                        legend: {
                            display: true,
                            position: 'bottom',
                        },
                        scale: {
                            ticks: { beginAtZero: true },
                        },
                    }}
                    plugins={[ChartDataLabels]}
                />
            )}
        </div>
    )
}

export default PolarAreaChart
