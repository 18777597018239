import React, {useEffect, useState} from 'react'
import { Chart as Charts } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import Api from '../Api/Api';
function BarChart() {
    const { SessionsCount } = Api();




    const GetSessionsList = async () => {
        try {
            let data = await SessionsCount();
            // setcatalogData(data.categories);
            console.log("SessionsCount", data.parameters);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        GetSessionsList();
    }, []);


    const alternatingColors = (count) => {
        const colors = [];
        for (let i = 0; i < count; i++) {
            // Choose different colors based on whether i is odd or even
            if (i % 2 === 0) {
                colors.push('#265936'); // Even color
            } else {
                colors.push('#9E7C0C'); // Odd color
            }
        }
        return colors;
    };
    return (
        <div>
           <Bar
                data={{
                    labels: [
                        'Jan', 
                        'Feb', 
                    ],
                    datasets: [
                        {
                            label: 'Meetings',
                            data: [20, 60, 100, 140, 200],
                            backgroundColor: "#265936", // 12 bars, one for each month
                            borderRadius: 50,
                        },
                        {
                            label: 'Session Count',
                            data: [40, 80, 120, 180, 60],
                            backgroundColor: "#9E7C0C", // 12 bars, one for each month
                            borderRadius: 50,
                        }
                    ]
                }}
                options={{
                    scales: {
                        x: {
                            type: 'category', // Specify the type of scale for x-axis
                            display: false
                        },
                       
                    }
                }}
            />
        </div>
    )
}

export default BarChart
