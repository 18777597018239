import React from 'react'
import { Line } from "react-chartjs-2";


function WaveLineChart() {

    return (
        <div>
            <Line
                data={{
                    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                    datasets: [
                        {
                            label: "Total Meetings",
                            data: [33, 53, 85, 41, 44, 65],
                            fill: false,
                            borderColor: "#2D9CDB",
                            tension: 0.46
                        },
                        {
                            label: "Streamed Meetings",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#265936",
                            tension: 0.46
                        },
                        {
                            label: "Remaining Meetings",
                            data: [38, 28, 38, 55, 59, 79],
                            fill: false,
                            borderColor: "#9E7C0C",
                            tension: 0.46
                        }
                    ]
                }}
            />
        </div>
    )
}

export default WaveLineChart
