import React, { useEffect, useState } from 'react'
import "./profile.css";
import profileImg from "../../assets/images/profile-img.png";
import placeholderImg from "../../assets/images/placeholder-image.jpg";
import Api from '../Api/Api';
import { useForm } from 'react-hook-form';
import { BiSolidEdit } from "react-icons/bi";
import ChangePassword from './ChangePassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MyProfile() {

    const { EditProfile, GetUserProfile } = Api();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [image, setImage] = useState(null);
    const [imageBinary, setImageBinary] = useState(null);


    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const binaryString = reader.result;
            console.log("binaryString", binaryString);
            setImageBinary(binaryString); 
        };
        reader.readAsBinaryString(selectedImage);
        setImage(URL.createObjectURL(selectedImage));
    };

    const onSubmit = async (data) => {
        debugger;
        try {
            let result = await EditProfile({ ...data, imageBinary });
            toast.success(result.message);
            console.log("imageBinary", result);
        } catch (err) {
            console.log(err);
        }
    };


    const GetProfile = async () => {
        debugger;
        try {
            const response = await GetUserProfile();
            console.log("response", response);
        //    console.log("GetUserProfile", response.parameters);
           setValue('FirstName', response?.parameters.first_name);
           setValue('LastName', response?.parameters.last_name);
           setValue('dob', response?.parameters.dob);
           setValue('presentaddress', response?.parameters.present_address);
           setValue('permanentaddress', response?.parameters.parmanent_address);
           setValue('city', response?.parameters.city);
           setValue('postalcode', response?.parameters.postal_code);
           setValue('country', response?.parameters.country);
          

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };



    useEffect(() => {
        // Profile();
        GetProfile();
    }, []);
    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page_title">
                            My Profile
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="profile_card">
                            <ul className="nav nav-tabs border-0 profile_tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#editProfile">Edit Profile</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#security">Security</a>
                                </li>
                            </ul>


                            <div className="tab-content mt-5">
                                <div id="editProfile" className="container tab-pane active">
                                    <form className='profile_form' onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                                                <div className='edit_profile-img position-relative'>
                                                    {image ? (
                                                        <img src={image} alt="profile" />
                                                    ) : (
                                                        <img src={placeholderImg} alt='no image' />
                                                    )}
                                                    <div className='img_edit'>
                                                        <BiSolidEdit className='edit_icon' />
                                                        <input type="file" onChange={handleImageChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-10">


                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="FirstName" class="form-label">First Name</label>
                                                            <input type="text"
                                                                className={`form-control ${errors.FirstName ? 'is-invalid' : ''}`}
                                                                id="FirstName" name='FirstName' placeholder="Enter First Name" {...register("FirstName", { required: true })} />
                                                            {errors.FirstName && <span className="invalid-feedback">First Name is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="LastName" class="form-label">Last Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="LastName"
                                                                placeholder="Enter Last Name"
                                                                name="LastName"
                                                                {...register("LastName", { required: false })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="dob" class="form-label">Date of Birth</label>
                                                            <input
                                                                type="date"
                                                                className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                                                                id="dob"
                                                                placeholder="Enter DOB"
                                                                name="dob"
                                                                {...register("dob", { required: true })}
                                                            />
                                                            {errors.dob && <span className="invalid-feedback">Date of Birth is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="presentaddress" class="form-label">Present Address</label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.presentaddress ? 'is-invalid' : ''}`}
                                                                id="presentaddress"
                                                                placeholder="Enter Present Address"
                                                                name="presentaddress"
                                                                {...register("presentaddress", { required: true })}
                                                            />
                                                            {errors.presentaddress && <span className="invalid-feedback">Present Address is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="permanentaddress" class="form-label">Permanent Address</label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.permanentaddress ? 'is-invalid' : ''}`}
                                                                id="permanentaddress"
                                                                placeholder="Enter Permanent Address"
                                                                name="permanentaddress"
                                                                {...register("permanentaddress", { required: true })}
                                                            />
                                                            {errors.permanentaddress && <span className="invalid-feedback">Permanent Address is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="city" class="form-label">City</label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                                id="city"
                                                                placeholder="Enter City Name"
                                                                name="city"
                                                                {...register("city", { required: true })}
                                                            />
                                                            {errors.city && <span className="invalid-feedback">City is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="postalcode" class="form-label">Postal Code</label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.postalcode ? 'is-invalid' : ''}`}
                                                                id="postalcode"
                                                                placeholder="Enter Postal Code"
                                                                name="postalcode"
                                                                {...register("postalcode", { required: true })}
                                                            />
                                                            {errors.postalcode && <span className="invalid-feedback">Postal Code is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                                        <div class="">
                                                            <label for="country" class="form-label">Country</label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                                                                id="country"
                                                                placeholder="Enter Country"
                                                                name="country"
                                                                {...register("country", { required: true })}
                                                            />
                                                            {errors.country && <span className="invalid-feedback">Country is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-3">
                                                        <div class="texts-end">
                                                            <button className='btn form_save-btn'>Save</button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div id="security" className="container tab-pane fade">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className='password_title'>Change Password</h3>
                                             <ChangePassword/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>

        </div>
    )
}

export default MyProfile
