var md5 = require("md5");

const Api = () => {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const SALT_KEY = "p@||l!@m~^t2024";
    const authToken_value = localStorage.getItem('auth_token');
    const User_ID = localStorage.getItem('user_id');
    async function SignIn(values) {
        try {
            console.log("values", values);
            const AUTHORIZATION = md5(`${SALT_KEY}` + values.password);
            console.log("AUTHORIZATION", AUTHORIZATION);
            values.auth_token = AUTHORIZATION;
            // localStorage.setItem('AUTHORIZATION', AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    // 'Origin': '*',
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            };

            const response = await fetch(`${apiUrl}/api/login`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during login:", error);
            throw error;
        }
    }

    async function SignUp(values) {
        try {
            console.log("values", values);
            const AUTHORIZATION = md5(`${SALT_KEY}` + values.password);
            console.log("AUTHORIZATION", AUTHORIZATION);
            values.auth_token = AUTHORIZATION;

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            };

            const response = await fetch(`${apiUrl}/api/register`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    // async function SignOut(values) {
    //     try {
    //         console.log("values", values);
    //         const AUTHORIZATION = md5(`${SALT_KEY}` + authToken_value);
    //         console.log("AUTHORIZATION", AUTHORIZATION);
    //         values.auth_token = AUTHORIZATION;

    //         const obj = {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(values),
    //         };

    //         const response = await fetch(`${apiUrl}/api/register`, obj);
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         return response.json();
    //     } catch (error) {
    //         console.error("Error occurred during register:", error);
    //         throw error;
    //     }
    // }

    async function ForgotAPI(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}` + values.email);
            console.log("AUTHORIZATION", AUTHORIZATION);
            values.auth_token = AUTHORIZATION;

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            };

            const response = await fetch(`${apiUrl}/api/forget-password`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function AddUserAPI(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}ADDUSER`);
            console.log("AUTHORIZATION", AUTHORIZATION);
            values.auth_token = AUTHORIZATION;

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "resetpassword",
                },
                body: JSON.stringify(values),
            };

            const response = await fetch(`${apiUrl}/api/add_user`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function EditUserAPI(user_id) {
        try { 
            
            const AUTHORIZATION = md5(`${SALT_KEY}` + user_id);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "resetpassword",
                },
                body: JSON.stringify({auth_token : AUTHORIZATION, user_id : user_id}),
            };

            const response = await fetch(`${apiUrl}/api/get_user_detail`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function UpdateUserAPI(values, user_id) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}` + user_id);
            console.log("AUTHORIZATION", AUTHORIZATION);
            values.auth_token = AUTHORIZATION;
            values.user_id = user_id;
    
            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "resetpassword",
                },
                body: JSON.stringify(values),
            };
    
            const response = await fetch(`${apiUrl}/api/edit_user`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    async function LiveVideoAPI() {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}LIVE_VIDEOS`);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "live-videos",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                }),
            };

            const response = await fetch(`${apiUrl}/api/live-videos`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function UpcomingVideoAPI() {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}UPCOMINGMEETINGSLIST`);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "upcoming_session_video",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    user_id:User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/upcoming_session_video`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function PreviousMeeting() {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}PREVIOUSMEETINGSLIST`);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "previous_session_video",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                }),
            };

            const response = await fetch(`${apiUrl}/api/previous_session_video`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function UsersList(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}USERSLIST`);
            console.log("AUTHORIZATION", AUTHORIZATION);
            // values.auth_token = AUTHORIZATION;

            // const authToken_value = "your_bearer_token_here"; // Define your bearer token
            // const apiUrl = "https://dev4.technotackle.in"; // Define your API base URL

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "users_list",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    page: values.page,
                    name: values.name,
                    email: values.email
                }),
            };

            const response = await fetch(`${apiUrl}/api/users_list`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function MeetingList(values) {
        console.log("MeetingList--", values);
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}MEETINGSLIST`);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "meetings_list",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    status: values.status,
                    from_date: values.fromDate,
                    to_date: values.toDate,
                    from_time: values.fromTime,
                    to_time: values.toTime,
                    page: values.page,
                    // status: "UPCOMING",
                    // from_date: "01-01-2024",
                    // to_date: "30-03-2024",
                    // from_time: "09:30:00",
                    // to_time: "12:00:00",
                    user_id:User_ID,
                }),
            };

            const response = await fetch(`${apiUrl}/api/meetings_list`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    async function MeetingDetails(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+values);
            console.log("MeetingDetails", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "single_meeting_detail",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    meeting_id: values,
                    user_id: User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/single_meeting_detail`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }
    // async function SingleMeeting() {
    //     try {
    //         const AUTHORIZATION = md5(`${SALT_KEY}meeting_id`);
    //         console.log("AUTHORIZATION", AUTHORIZATION);

    //         const obj = {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 'Authorization': `Bearer ${authToken_value}`,
    //                 "x-api-key": "single_meeting_detail",
    //             },
    //             body: JSON.stringify({
    //                 auth_token: AUTHORIZATION,
    //             }),
    //         };

    //         const response = await fetch(`${apiUrl}/api/single_meeting_detail`, obj);
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         return response.json();
    //     } catch (error) {
    //         console.error("Error occurred during register:", error);
    //         throw error;
    //     }
    // }


    async function FavoriteList() {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}FAVORITEMEETINGSLIST`);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "favorite_video",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    user_id:User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/favorite_video`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }



    async function AddFavoriteVideo(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+User_ID);
            console.log("AddFavoriteVideo", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "add_to_favorite",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    video_id: values,
                    user_id: User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/add_to_favorite`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function RemoveFavoriteVideo(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+User_ID);
            console.log("remove_from_favorite", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "remove_from_favorite",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    video_id: values,
                    user_id: User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/remove_from_favorite`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    async function AddLikeVideo(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+User_ID);
            console.log("AddFavoriteVideo", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "add_to_liked",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    video_id: values,
                    user_id: User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/add_to_liked`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function RemoveLikeVideo(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+User_ID);
            console.log("AddFavoriteVideo", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "remove_from_liked",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    video_id: values,
                    user_id: User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/remove_from_liked`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function LikedList() {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}LIKEDMEETINGSLIST`);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "liked_video",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    user_id:User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/liked_video`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }



    async function TitleMeetingCount(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}MEETING_COUNT`+User_ID);
            console.log("AddFavoriteVideo", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "title-wise-meeting-count",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    page: values.page,
                    search: values.title
                }),
            };

            const response = await fetch(`${apiUrl}/api/title-wise-meeting-count`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    
    async function TrendingTopics(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}TRENDING_TOPICS`+User_ID);
            console.log("AddFavoriteVideo", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "trending-topics",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION
                }),
            };

            const response = await fetch(`${apiUrl}/api/trending-topics`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    async function SessionsCount(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}SESSION_COUNT_AND_MEETINGS`+User_ID);
            console.log("AddFavoriteVideo", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "sessions-count-and-meetings",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION
                }),
            };

            const response = await fetch(`${apiUrl}/api/sessions-count-and-meetings`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }

    async function EditProfile(values) {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+User_ID);
            console.log("AddFavoriteVideo", values);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "edit_profile",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    user_id:User_ID,
                    first_name:values.FirstName,
                    last_name:values.LastName,
                    profile_image:values.formData,
                    dob:values.dob,
                    present_address:values.presentaddress,
                    parmanent_address:values.permanentaddress,
                    city:values.city,
                    country:values.country,
                    postal_code:values.postalcode
                }),
            };

            const response = await fetch(`${apiUrl}/api/edit_profile`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    async function ChangePassword(values) {
        debugger;
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+User_ID+"CHANGE_PASSWORD");
            console.log("CHANGE_PASSWORD", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "change-password",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    current_password:values.currentpassword,
                    password:values.newpassword,
                    user_id:User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/change-password`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }



    async function GlobalSearchList() {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}MEETINGSLIST`);
            console.log("AUTHORIZATION", AUTHORIZATION);

            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "meetings_list",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    user_id:User_ID
                }),
            };

            const response = await fetch(`${apiUrl}/api/meetings_list`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    async function GetUserProfile() {
        try {
            const AUTHORIZATION = md5(`${SALT_KEY}`+User_ID);
            const obj = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authToken_value}`,
                    "x-api-key": "get_user_profile",
                },
                body: JSON.stringify({
                    auth_token: AUTHORIZATION,
                    user_id:User_ID,
                }),
            };

            const response = await fetch(`${apiUrl}/api/get_user_profile`, obj);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error("Error occurred during register:", error);
            throw error;
        }
    }


    return {
        SignIn,
        SignUp,
        // SignOut,
        ForgotAPI,
        AddUserAPI,
        EditUserAPI,
        UpdateUserAPI,
        LiveVideoAPI,
        UsersList,
        PreviousMeeting,
        MeetingList,
        MeetingDetails,
        // SingleMeeting,
        FavoriteList,
        UpcomingVideoAPI,
        AddFavoriteVideo,
        RemoveFavoriteVideo,
        AddLikeVideo,
        RemoveLikeVideo,
        LikedList,
        TitleMeetingCount,
        TrendingTopics,
        SessionsCount,
        EditProfile,
        ChangePassword,
        GlobalSearchList,
        GetUserProfile
    }


    // return (
    //     <div>

    //         <p>{SALT}</p>
    //         <p>{apiUrl}</p>
    //     </div>


    // )
}

export default Api
