import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Api from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddUser() {
    const { AddUserAPI } = Api();
    const { register, handleSubmit, reset,  formState: { errors } } = useForm();

    // const onSubmit = async (value) => {
    //     try {
    //         const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldjQudGVjaG5vdGFja2xlLmluL3BhcmxpYW1lbnQvcHVibGljL2FwaS9sb2dpbiIsImlhdCI6MTcxMDc1OTMyNiwiZXhwIjoxNzEwNzYyOTI2LCJuYmYiOjE3MTA3NTkzMjYsImp0aSI6Im1qZXlINm1xMHlOV09wangiLCJzdWIiOiIxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.Bn-7qgLPBW2v29rlFGWFEAZEegwiXqw8-h71Vc5x9wQ";
    //         const AUTHORIZATION = "d3215a471380f7cbbcb484ae7ec0fe27";
    //         value.auth_token = AUTHORIZATION;

    //         const headers = {
    //             "Content-Type": "application/json",
    //             'Authorization': `Bearer ${authToken}`,
    //             "x-api-key": "resetpassword",
    //         };

    //         const body = JSON.stringify(value);
    //         const response = await axios.post('https://dev4.technotackle.in/parliament/public/api/add_user', body, { headers });

    //         console.log(response.data.message);
    //         alert(response.data.message);
    //     } catch (error) {
    //         console.error('Error adding user:', error);
    //         alert('Failed to add user. Please try again later.');
    //     }
    // };



    const onSubmit = async (data) => {
        debugger;
        try {
            const response = await AddUserAPI(data);
            if (response.success) {
                toast.success(response.message);
                reset();
                // Navigate('/settings');
            } else if(response.parameters.email) {
                // toast.error(response.error);
                toast.error(response.parameters.email[0]);
            }
            else{
                toast.error(response.parameters.password[0]);
            }
        } catch (error) {
        }
    };



    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page_title">
                            Add User
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="profile_card">
                            <form className='profile_form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor="name" className="form-label">Your Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Enter Your Name" {...register("name", { required: true })} />
                                            {errors.name && <span className="error-danger">Name is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="email" className="form-control" id="email" placeholder="Enter Email Address" {...register("email", {
                                                required: "Please enter Email ID",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Please enter valid Email ID"
                                                }
                                            })} />
                                            {errors.email && <span className="error-danger">{errors.email.message}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor="role" className="form-label d-block">Role</label>
                                            <select name="role" id="" className='d-block w-100' {...register("role", { required: true })}>
                                                <option value="">Select Role</option>
                                                <option value="Admin">Admin</option>
                                                <option value="User">User</option>
                                            </select>
                                            {errors.role && <span className="error-danger">Role is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor="status" className="form-label d-block">Status</label>
                                            <select name="status" id="" className='d-block w-100' {...register("status", { required: true })}>
                                                <option value="">Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                            {errors.status && <span className="error-danger">Status is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type="password" className="form-control" id="password" placeholder="Enter Password" {...register("password", { required: true })} />
                                            {errors.password && <span className="error-danger">Password is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="texts-end">
                                            <Link to="/admin/settings" className='btn form_cancel-btn me-3'>Cancel</Link>
                                            <button type="submit" className='btn form_save-btn'>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddUser;