import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Api from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EditUser() {
    const { user_id } = useParams();
    const { UpdateUserAPI } = Api();
    const { EditUserAPI } = Api();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        fetchData();
    }, [user_id]);


    // const fetchData = async () => {
    //     try {
    //         const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldjQudGVjaG5vdGFja2xlLmluL3BhcmxpYW1lbnQvcHVibGljL2FwaS9sb2dpbiIsImlhdCI6MTcxMDg0ODQ4MywiZXhwIjoxNzEwODUyMDgzLCJuYmYiOjE3MTA4NDg0ODMsImp0aSI6IjA5VnFoUWN6N3hVWkVXT2IiLCJzdWIiOiIxMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.Ybbm_w4BfqVQOH0K8h4R8wIdANRdh625FKo46pQk61M";

    //         const response = await fetch('https://dev4.technotackle.in/parliament/public/api/get_user_detail', {
    //             method: 'POST',
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 'Authorization': `Bearer ${authToken}`,
    //                 "x-api-key": "resetpassword",
    //             },
    //             body: JSON.stringify({
    //                 auth_token: '0eebdfadb668a7956ab1ee6d5298539c',
    //                 user_id: '1'
    //             })
    //         });

    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         const data = await response.json();
    //         setValue('name', data?.parameters.name);
    //         setValue('email', data?.parameters.email);
    //         setValue('role', data?.parameters.role);
    //         setValue('status', data?.parameters.status);
    //     } catch (error) {
    //         console.error('There was a problem with the fetch operation:', error);
    //     }
    // };


    const fetchData = async () => {
        try {
            const response = await EditUserAPI(user_id);
            setValue('name', response?.parameters.name);
            setValue('email', response?.parameters.email);
            setValue('role', response?.parameters.role);
            setValue('status', response?.parameters.status);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const onSubmit = async (data) => {
        try {
            const response = await UpdateUserAPI(data, user_id);
            if (response.success) {
                toast.success(response.message);
            }
            else if (response.parameters.email) {
                toast.error(response.parameters.email[0]);
            }
            else {
                toast.error(response.error);
            }
        } catch (error) {
            console.error('Error occurred during form submission:', error);
        }
    };



    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page_title">
                            Edit User
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="profile_card">
                            <form className='profile_form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label className="form-label">Your Name</label>
                                            <input type="text" className="form-control" id="name" name="name" placeholder="Enter Your Name" {...register("name", { required: true })} />
                                            {errors.name && <span className="error-danger">Name is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Enter Email Address" {...register("email", {
                                                required: "Please enter Email ID",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Please enter valid Email ID"
                                                }
                                            })} />
                                            {errors.email && <span className="error-danger">{errors.email.message}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label className="form-label d-block">Role</label>
                                            <select name="role" id="role" className='form-control' {...register("role", { required: true })}>
                                                <option value="">Select Role</option>
                                                <option value="ADMIN">Admin</option>
                                                <option value="USER">User</option>
                                            </select>
                                            {errors.role && <span className="error-danger">Role is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label className="form-label d-block">Status</label>
                                            <select name="status" id="status" className='form-control' {...register("status", { required: true })}>
                                                <option value="">Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                            {errors.status && <span className="error-danger">Status is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="texts-end">
                                            <Link to="/admin/settings" className='btn form_cancel-btn me-3'>Cancel</Link>
                                            <button type="submit" className='btn form_save-btn'>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default EditUser;