import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from "../../assets/images/parliament-logo.png";
import closeIcon from "../../assets/images/circle-close.svg";
import "./forgot.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import Api from '../Api/Api';
function ForgotPassword({setlogged_in}) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const { ForgotAPI } = Api();
    const { register, handleSubmit, formState: { errors }, setError, reset } = useForm();
    const location = useLocation();
    const isAdminLogin = location.pathname === "/admin/forgot-password";

    const onSubmit = async (data) => {
        debugger;
        try {
            const response = await ForgotAPI(data);
            console.log("ForgotAPI", data);
            if (response.success) {
                toast.success(response.message);
                setlogged_in(true);
                // navigate('/');
            } else {
                // Handle login failure
                console.log("Forgot password failed");
            }
            
        } catch (error) {
            console.error("Error occurred during login:", error.message);
        }
    };


    const handleClearEmail = () => {
        reset({ email: '' });
    };
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div className="main_body">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6">
                        <div className="form_container">
                            <div className='login_logo mb-3 text-center'>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className="login_form-inner">
                                <div className='login_title'>Forgot Password</div>
                                <div className='login_sm-title'>Enter your Email ID to change password</div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='input_from position-relative'>
                                        <input
                                            type="email"
                                            placeholder='Enter Email'
                                            onChange={handleChangeEmail}
                                            {...register("email", {
                                                required: "Please enter Email ID.",
                                                pattern: {
                                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                    message: "Please enter valid Email ID."
                                                }
                                            })}
                                        />
                                        {errors.email && <span className="error_message">{errors.email.message}</span>}
                                        {email !== '' && (
                                            <span className='text_close' onClick={handleClearEmail}>
                                                <img src={closeIcon} alt="close" />
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <button type="submit" className='login_submit btn w-100 text-white'>Reset Password</button>
                                    </div>
                                </form>

                                <div className='google_btn'>
                                    <div className='have_login text-center my-4'>
                                        
                                        Already have an Account ? 
                                        {isAdminLogin ? (
                                             <Link to="/">Login</Link>
                                        ) : (
                                            <Link to="/">Login</Link>
                                        )}
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="copy_right text-center">
                                Copyright 2024. All Rights Reserved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ForgotPassword;