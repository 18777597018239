import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Api from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeIcon from "../../assets/images/eye.svg";
import eyeIcon2 from "../../assets/images/slash-eye.svg";
function ChangePassword() {
    const { register, handleSubmit, formState: { errors }, setError, watch, clearErrors } = useForm();
    const { ChangePassword } = Api();


    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);


    const togglePasswordVisibility = (field) => {
        switch (field) {
            case 'current':
                setShowCurrentPassword(!showCurrentPassword);
                break;
            case 'new':
                setShowNewPassword(!showNewPassword);
                break;
            case 'confirm':
                setShowConfirmNewPassword(!showConfirmNewPassword);
                break;
            default:
                break;
        }
    };


    const onSubmit = async (data) => {
        debugger;
        try {
            if (data.newpassword !== data.confirmnewpassword) {
                // Passwords don't match, show an alert
                toast.error("New Password and Confirm New Password do not match");
                return; // Exit early
            }
            const result = await ChangePassword(data);
            console.log("ChangePassword", result);
            if (result.success) {
                toast.success(result.message);

            } else {
                // Handle login failure
                console.log("Login failed");
                toast.error(result.message);
            }
        } catch (err) {
            console.log(err);
        }
    };




    return (
        <div>
            <form className='profile_form change_password' onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-2">
                        <div class="">
                            <label for="currentpassword" class="form-label">Current Password</label>
                            <div className='input_from position-relative input_from-password'>
                                <input
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    className="form-control"
                                    id="currentpassword"
                                    placeholder="Enter Your Password"
                                    name="currentpassword"
                                    {...register("currentpassword", { required: "Current Password is required" })}
                                />
                                {errors.currentpassword && <span className="error_message">{errors.currentpassword.message}</span>}
                                {/* {errors.currentpassword && <span className="invalid-feedback">Current Password is required</span>} */}
                                <span className='text_visible' onClick={() => togglePasswordVisibility('current')}>
                                    {showCurrentPassword ? (
                                        <img src={eyeIcon} alt="hide" />
                                    ) : (
                                        <img src={eyeIcon2} alt="show" />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-2">
                        <div class="">
                            <label for="newpassword" class="form-label">New Password</label>
                            <div className='input_from position-relative input_from-password'>
                                <input
                                    type={showNewPassword ? 'text' : 'password'}
                                    className="form-control"
                                    id="newpassword"
                                    placeholder="Enter New Password"
                                    name="newpassword"
                                    {...register("newpassword", { required: "New Password is required" })}
                                />
                                {errors.newpassword && <span className="error_message">{errors.newpassword.message}</span>}
                                {/* {errors.newpassword && <span className="invalid-feedback">New Password is required</span>} */}

                                <span className='text_visible' onClick={() => togglePasswordVisibility('new')}>
                                    {showCurrentPassword ? (
                                        <img src={eyeIcon} alt="hide" />
                                    ) : (
                                        <img src={eyeIcon2} alt="show" />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-2">
                        <div class="">
                            <label for="confirmnewpassword" class="form-label">Confirm New Password</label>
                            <div className='input_from position-relative input_from-password'>
                                <input
                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                    className="form-control"
                                    id="confirmnewpassword"
                                    placeholder="Enter Confirm New Password"
                                    name="confirmnewpassword"
                                    {...register("confirmnewpassword", { required: "Confirm New Password is required" })}
                                />
                                {errors.confirmnewpassword && <span className="error_message">{errors.confirmnewpassword.message}</span>}
                                {/* {errors.confirmnewpassword && <span className="invalid-feedback">Confirm New Password is required</span>} */}
                                <span className='text_visible' onClick={() => togglePasswordVisibility('confirm')}>
                                    {showCurrentPassword ? (
                                        <img src={eyeIcon} alt="hide" />
                                    ) : (
                                        <img src={eyeIcon2} alt="show" />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div class="texts-end">
                            <button className='btn form_save-btn'>Save</button>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}

export default ChangePassword
