import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import logo from "../../assets/images/parliament-logo.png";
import eyeIcon from "../../assets/images/eye.svg";
import eyeIcon2 from "../../assets/images/slash-eye.svg";
import './reset.css';

function ResetPassword() {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  return (
    <div className="main_body">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6">
                        <div className="form_container">
                            <div className='login_logo mb-3 text-center'>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className="login_form-inner">
                                <div className='login_title'>Reset Password</div>
                                <div className='login_sm-title'>Enter New Password to reset your Password</div>
                                <form>
                                    <div className='input_from position-relative'>
                                    <input
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter Password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <span className='text_visible' onClick={togglePasswordVisibility}>
                                            {showPassword ? (
                                                <img src={eyeIcon} alt="hide" />
                                            ) : (
                                                <img src={eyeIcon2} alt="show" />
                                            )}
                                        </span>
                                    </div>
                                    <div className='input_from position-relative'>
                                    <input
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Confirm New Password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <span className='text_visible' onClick={togglePasswordVisibility}>
                                            {showPassword ? (
                                                <img src={eyeIcon} alt="hide" />
                                            ) : (
                                                <img src={eyeIcon2} alt="show" />
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <button className='login_submit btn w-100 text-white'>Reset Password</button>
                                    </div>
                                </form>

                                <div className='google_btn'>
                                    <div className='have_login text-center my-4'>
                                        Already have an Account ? <Link to="/user/login">Login</Link>
                                    </div>
                                </div>


                            </div>

                            <div className="copy_right text-center t_small_none">
                                Copyright 2024. All Rights Reserved
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ResetPassword
