import React, { useEffect, useRef, useState } from 'react';
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import 'bootstrap-datepicker';
import $ from 'jquery';
import '../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import BarChart from './BarChart';
import "./dashboard.css";
import { FaCircle } from "react-icons/fa6";
import WaveLineChart from './WaveLineChart';
import MeetingList from './MeetingList';
import PolarAreaChart from './PolarAreaChart';
import { LiaTimesSolid } from "react-icons/lia";
function Dashboard() {

    const [isVisible, setIsVisible] = useState(false);
    const [issmallVisible, setIssmallVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    const handleClose = () => {
        setIsVisible(false);
    };

    const smalltoggleVisibility = () => {
        setIssmallVisible(!issmallVisible);
    };
    const handleCloses = () => {
        setIssmallVisible(false);
    };


    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);
    useEffect(() => {
        $(fromDateRef.current).datepicker();
        $(toDateRef.current).datepicker();
    }, []);


    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mob_fil_align">
                            <div className="page_title">
                                Dashboard
                            </div>
                            <div className="mobile_filter_icon mob_fills_icon" onClick={toggleVisibility}>
                                <img src={filter_icon} alt="filter" />
                            </div>
                        </div>
                    </div>
                </div>


                {isVisible && (
                    <div className="filter_box mobile_filter">
                        <div className="filt_head_box">
                            <div className="filt_text-dark">Filter by</div>
                            <div className="filt_close_icon" onClick={handleClose}><LiaTimesSolid /></div>
                        </div>
                        <div className="filt_content_box">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-lg-12 mb-2">
                                    <label className="filt_label">Status</label>
                                    <select className="form-control fills_control">
                                        <option>Status</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12 mb-2">
                                    <label className="filt_label">From Date</label>
                                    <input
                                        ref={fromDateRef}
                                        type="text"
                                        className="form-control fills_control"
                                        placeholder="From Date"
                                    />
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12 mb-2">
                                    <label className="filt_label">To Date</label>
                                    <input
                                        ref={toDateRef}
                                        type="text"
                                        className="form-control fills_control"
                                        placeholder="To Date"
                                    />
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12 mb-2">
                                    <label className="filt_label">From Time</label>
                                    <select className="form-control fills_control">
                                        <option>From Time</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12 mb-2">
                                    <label className="filt_label">To Time</label>
                                    <select className="form-control fills_control">
                                        <option>To Time</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12 mb-2 mt-3">
                                    <div className="reset_filter filter_col d-flex align-items-center justify-content-center">
                                        <img src={reload_icon} alt="reset" className='me-2' />  Reset Filter
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12 mb-2 text-center mt-2">
                                    <button class="btn form_save-btn" onClick={handleClose}>Show Results</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                <div className="row desktop_filter mb-3">
                    <div className="col-12">
                        <div className="filter_container">
                            <div className="filter_icon filter_col">
                                <img src={filter_icon} alt="filter" />
                            </div>
                            <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
                                Filter By
                            </div>
                            <div className="status_filter filter_col">
                                <select className="form-control" id="status">
                                    <option value="">Status</option>
                                    <option value="Upcoming">Upcoming</option>
                                    <option value="Completed">Completed</option>
                                    <option value="In-progress">In-progress</option>
                                </select>
                            </div>
                            <div className="from_date-filter filter_col">
                                <input
                                    ref={fromDateRef}
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="From Date"
                                />
                            </div>
                            <div className="to_date-filter filter_col">
                                <input
                                    ref={toDateRef}
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="To Date"
                                />
                            </div>
                            <div className="from_time-filter filter_col">
                                <select class="form-control" id="fromTime">
                                    <option>From Time</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <div className="to_time-filter filter_col">
                                <select class="form-control" id="toTime">
                                    <option>To Time</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <div className="reset_filter filter_col d-flex align-items-center justify-content-center">
                                <img src={reload_icon} alt="reset" className='me-2' />  Reset Filter
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mb-3">
                    <div className="col-12 col-sm-12 col-md-8 mb-3">
                        <div className="chart_card bg-white p-3">
                            <h3>Sessions Count</h3>
                            <BarChart />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                        <div className="chart_card bg-white p-3 h-100">
                            <h3>Glitch Statistics</h3>
                            <div className='glitch_list'>
                                <ul className=''>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span>
                                            <FaCircle className='me-2 color-1' />
                                            <span className='glitch_title'>Audio Glitch</span>
                                        </span>
                                        <span className='glitch_count'>3</span>
                                    </li>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span><FaCircle className='me-2 color-2' />
                                            <span className='glitch_title'>Delayed Coverage</span>
                                        </span>
                                        <span className='glitch_count'>1</span>
                                    </li>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span><FaCircle className='me-2 color-3' />
                                            <span className='glitch_title'>Dropped Frames</span>
                                        </span>
                                        <span className='glitch_count'>64</span>
                                    </li>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span><FaCircle className='me-2 color-4' />
                                            <span className='glitch_title'>Glitch from source</span>
                                        </span>
                                        <span className='glitch_count'>5</span>
                                    </li>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span><FaCircle className='me-2 color-5' />
                                            <span className='glitch_title'>Glitch Audio and Visual</span>
                                        </span>
                                        <span className='glitch_count'>1</span>
                                    </li>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span><FaCircle className='me-2 color-6' />
                                            <span className='glitch_title'>Refeed</span>
                                        </span>
                                        <span className='glitch_count'>29</span>
                                    </li>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span><FaCircle className='me-2 color-7' />
                                            <span className='glitch_title'>Successfull</span>
                                        </span>
                                        <span className='glitch_count'>572</span>
                                    </li>
                                    <li className='d-flex align-items-center justify-content-between'>
                                        <span><FaCircle className='me-2 color-8' />
                                            <span className='glitch_title'>Technical Issues from Feed</span>
                                        </span>
                                        <span className='glitch_count'>3</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="row mb-3">
                    <div className="col-12">
                        <div className='chart_card bg-white p-3'>
                            <h3>Meetings</h3>
                            <WaveLineChart />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                        <div className='chart_card'>


                            <MeetingList />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                        <div className='chart_card bg-white p-3 h-100'>
                            <h3>Trending Topics</h3>
                            <PolarAreaChart />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Dashboard
