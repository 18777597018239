import React, { useState, useEffect } from 'react';
import meetingImg from "../../assets/images/meeting.png"
import { Link, useParams } from 'react-router-dom';
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import copyIcon from "../../assets/images/copy_icon.png";
import shareIcon from "../../assets/images/share_icon.png";
import fbSmIcon from "../../assets/images/fb_icon1.png";
import whatsappIcon from "../../assets/images/whatsapp_icon.png";
import youtubeIcon from "../../assets/images/youtube_icon.png";
import Api from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MeetingDetail() {
    const { videoId } = useParams()
    console.log("meetId", videoId);
    const [showReports, setShowReports] = useState(false);
    const [meetDetails, setMeetDetails] = useState();
    const [YoutubeLink, setYoutubeLink] = useState();
    const [FacebookLink, setFacebookLink] = useState();
    const [TwitterLink, setTwitterLink] = useState();
    const [showReportButton, setShowReportButton] = useState(true);
    const { MeetingDetails } = Api();

    const toggleReports = () => {
        setShowReports(!showReports);
        setShowReportButton(!showReportButton);
    };

    const submitReport = () => {
        // Logic to submit report
        setShowReports(false); // Hide the report section after submission
        setShowReportButton(true); // Show the report button after submission
    };

    useEffect(() => {
        GetDetails();
    }, []);

    const GetDetails = async () => {
        try {
            let data = await MeetingDetails(videoId);
            // setcatalogData(data.categories);
            // console.log("meeting", data.parameters);
            setMeetDetails(data.parameters);
            setYoutubeLink(data.parameters.youtube_link);
            setFacebookLink(data.parameters.facebook_link);
            setTwitterLink(data.parameters.twitter_link);
            // setMeetingData(data.parameters.data);
        } catch (err) {
            console.log(err);
        }
    };



    const shareOnWhatsApp = () => {
        const url = encodeURIComponent(meetDetails.youtube_link);
        const text = encodeURIComponent('Check out this link!');
        const whatsappUrl = `https://wa.me/?text=${text}%20${url}`;
        window.open(whatsappUrl, '_blank');
    };

    const shareOnFacebook = () => {
        const url = encodeURIComponent(meetDetails.youtube_link);
        const text = encodeURIComponent('Check out this link!');
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
        window.open(facebookUrl, '_blank');
    };

    const shareOnYouTube = () => {
        const url = encodeURIComponent(meetDetails.youtube_link);
        const text = encodeURIComponent('Check out this link!');
        const youtubeUrl = `https://www.youtube.com/watch?v=${url}&utm_source=share&utm_medium=ios_app&utm_name=iossmf`;
        window.open(youtubeUrl, '_blank');
    };


    const copyYoutubeLink = () => {
        const urlValue = meetDetails.youtube_link;
        navigator.clipboard.writeText(urlValue)
            .then(() => {
                toast.success("URL copied to clipboard!");
            })
            .catch((error) => {
                console.error('Failed to copy:', error);
            });
    }

    const copyFacebookLink = () => {
        const urlValue = meetDetails.facebook_link;
        navigator.clipboard.writeText(urlValue)
            .then(() => {
                toast.success("URL copied to clipboard!");
            })
            .catch((error) => {
                console.error('Failed to copy:', error);
            });
    }

    const copyTwitterLink = () => {
        const urlValue = meetDetails.twitter_link;
        navigator.clipboard.writeText(urlValue)
            .then(() => {
                toast.success("URL copied to clipboard!");
            })
            .catch((error) => {
                console.error('Failed to copy:', error);
            });
    }

    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page_title">
                            Meeting Details
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="meeting_card">

                            <div className="row">
                                <div className="col-12">
                                    <h3 className='meet_title'>
                                        {meetDetails?.video_title}
                                    </h3>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='meet_schedule'>
                                            {meetDetails?.video_date} - {meetDetails?.from_time.substring(0, 5)} to {meetDetails?.to_time.substring(0, 5)}
                                        </p>
                                        <p className='meet_like'>
                                            {meetDetails?.status}
                                        </p>
                                    </div>
                                    <div className='meet_image mb-4'>
                                        <img src={meetingImg} alt="meeting" />
                                    </div>
                                    <div className="meet_description mb-4">
                                        <h3 className='meet_description-title'>Title and Description</h3>
                                        <p>{meetDetails?.video_description}</p>
                                    </div>
                                    <div className="meet_description mb-4">
                                        <h3 className='meet_description-title'>To Visit in Social Media Platforms</h3>

                                        <ul className='meet_media mb-2'>
                                            <li>
                                                <img src={playIcon} alt="play" />
                                            </li>
                                            <li><Link to={meetDetails?.youtube_link} className="link_control" target='_blank'>{meetDetails?.youtube_link}</Link> </li>
                                            <li>
                                                <div className='share_btn' onClick={copyYoutubeLink}>
                                                    <img src={copyIcon} alt="copy" />
                                                </div>
                                            </li>
                                            <li>
                                                <button class="share_btn" type="button" id="shareDropdownButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={shareIcon} alt="copy" />
                                                </button>
                                                <ul class="share_dropdown dropdown-menu" aria-labelledby="shareDropdownButton">
                                                    <li className='share_title text-center'>Share link via </li>
                                                    <li className='social_list'>
                                                        <span><div><img src={whatsappIcon} onClick={shareOnWhatsApp} alt="whatsappIcon" /> </div> </span>
                                                        <span><div><img src={fbSmIcon} onClick={shareOnFacebook} alt="fbIcon" /> </div> </span>
                                                        <span><div><img src={youtubeIcon} onClick={shareOnYouTube} alt="youtubeIcon" /> </div> </span>
                                                    </li>

                                                </ul>


                                            </li>
                                        </ul>

                                        <ul className='meet_media mb-2'>
                                            <li>
                                                <img src={fbIcon} alt="play" />
                                            </li>
                                            <li><Link to={meetDetails?.facebook_link} className="link_control" target='_blank'>{meetDetails?.facebook_link}</Link> </li>
                                            <li>
                                                <div className='share_btn' onClick={copyFacebookLink}>
                                                    <img src={copyIcon} alt="copy" />
                                                </div>
                                            </li>
                                            <li>
                                                <button class="share_btn" type="button" id="shareDropdownButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={shareIcon} alt="copy" />
                                                </button>
                                                <ul class="share_dropdown dropdown-menu" aria-labelledby="shareDropdownButton">
                                                    <li className='share_title text-center'>Share link via </li>
                                                    <li className='social_list'>
                                                        <span><Link><img src={whatsappIcon} alt="whatsappIcon" /> </Link> </span>
                                                        <span><Link><img src={fbSmIcon} alt="fbIcon" /> </Link> </span>
                                                        <span><Link><img src={youtubeIcon} alt="youtubeIcon" /> </Link> </span>
                                                    </li>

                                                </ul>
                                            </li>
                                        </ul>

                                        <ul className='meet_media mb-2'>
                                            <li>
                                                <img src={twitterIcon} alt="play" />
                                            </li>
                                            <li><Link to={meetDetails?.twitter_link} className="link_control" target='_blank'>{meetDetails?.twitter_link}</Link> </li>
                                            <li>
                                                <div className='share_btn' onClick={copyTwitterLink}>
                                                    <img src={copyIcon} alt="copy" />
                                                </div>
                                            </li>
                                            <li>
                                                <button class="share_btn" type="button" id="shareDropdownButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={shareIcon} alt="copy" />
                                                </button>
                                                <ul class="share_dropdown dropdown-menu" aria-labelledby="shareDropdownButton">
                                                    <li className='share_title text-center'>Share link via </li>
                                                    <li className='social_list'>
                                                        <span><Link><img src={whatsappIcon} alt="whatsappIcon" /> </Link> </span>
                                                        <span><Link><img src={fbSmIcon} alt="fbIcon" /> </Link> </span>
                                                        <span><Link><img src={youtubeIcon} alt="youtubeIcon" /> </Link> </span>
                                                    </li>

                                                </ul>
                                            </li>
                                        </ul>

                                    </div>

                                    <div className="meet_description mb-4">
                                        <h3 className='meet_description-title'>Report</h3>
                                        {showReportButton && (
                                            <div className='whatsapp_report d-flex gap-3 align-items-center'>
                                                <button className='btn' onClick={toggleReports}>Report</button>
                                            </div>
                                        )}

                                        {showReports && (
                                            <div className="show_reports">
                                                <div className="mt-3">
                                                    <label for="report1" className="radio_flex">
                                                            <div className="cc_inputs"><input name="report" id='report1' type="radio" value="option1" className="accent_changes" /></div>
                                                            <div className="cc_labels"><span className="radios_label"> Internet is not Stable</span></div>
                                                     </label>
                                                    <label for="report2" className="radio_flex"   >
                                                        <div className="cc_inputs"><input name="report" id='report2' type="radio" value="option2" className="accent_changes" /></div>
                                                        <div className="cc_labels"><span className="radios_label"> Link is not working</span></div>
                                                    </label>
                                                    <label for="report3" className="radio_flex">
                                                        <div className="cc_inputs"><input name="report" id='report3' type="radio" value="option3" className="accent_changes" /></div>
                                                        <div className="cc_labels"><label className="radios_label"> Video is not working</label></div>
                                                    </label>
                                                    <label for="report4" className="radio_flex">
                                                        <div className="cc_inputs"><input name="report" id='report4' type="radio" value="option4" className="accent_changes" /></div>
                                                        <div className="cc_labels"><label className="radios_label"> Only half video is playing</label></div>
                                                    </label>
                                                    <label for="report5" className="radio_flex">
                                                        <div className="cc_inputs"><input name="report" id='report5' type="radio" value="option5" className="accent_changes" /></div>
                                                        <div className="cc_labels"><label className="radios_label"> Troubleshoot Error</label></div>
                                                    </label>
                                                    <label for="report6" className="radio_flex">
                                                        <div className="cc_inputs"><input name="report" id='report6' type="radio" value="option5" className="accent_changes" /></div>
                                                        <div className="cc_labels"><label className="radios_label"> Others</label></div>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <button className="btn form_save-btn" onClick={submitReport}>Submit Report</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default MeetingDetail;