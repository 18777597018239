import React, { useState, useEffect } from 'react';
import '../login/login.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from "../../assets/images/parliament-logo.png";
import bg from "../../assets/images/login-bg.png";
import closeIcon from "../../assets/images/circle-close.svg";
import eyeIcon from "../../assets/images/eye.svg";
import eyeIcon2 from "../../assets/images/slash-eye.svg";
import Api from '../Api/Api';
import { useForm, SubmitHandler } from "react-hook-form"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login({ setlogged_in }) {
    const [logged_in, setlogged_inLocal] = useState(false);
    const [emails, setEmails] = useState('');
    const [email, setEmail] = useState('');
    const [passwordError, setPasswordError] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const { SignIn } = Api();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const notify = () => toast("Wow so easy!");
    const user_role = localStorage.getItem('user_role');
    const location = useLocation();
    const isAdminLogin = location.pathname === "/admin/login";
    // const LoginSuccess = () => toast("Login successful! Redirecting to your dashboard...!");


    useEffect(() => {
        const storedLoggedIn = localStorage.getItem('logged_in');
        if (storedLoggedIn) {
            setlogged_inLocal(JSON.parse(storedLoggedIn));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('logged_in', JSON.stringify(logged_in));
    }, [logged_in]);

    // const handleLogin = () => {
    //     setlogged_inLocal(true);
    // };
    // const handleLogout = () => {
    //     setlogged_inLocal(false);
    // };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };
    const clearEmail = () => {
        // setEmail('');
        setValue("email", "")
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (data) => {
        debugger;
        try {
            const response = await SignIn(data);
            console.log("login", data);
            if (response.success) {
                toast.success(response.message);
                setlogged_in(true);
                setlogged_inLocal(true);

                if (user_role === "ADMIN") {
                    navigate('/admin/dashboard');
                }
                else {
                    navigate('/user/livevideos');
                }

                console.log("login response", response.parameters.token);
                localStorage.setItem('auth_token', response.parameters.token);
                localStorage.setItem('user_id', response.parameters.user.id);
                localStorage.setItem('email_id', response.parameters.user.email);
                localStorage.setItem('user_name', response.parameters.user.name);
                localStorage.setItem('user_role', response.parameters.user.role);
                localStorage.setItem('logged_in', response.success);

            } else {
                // Handle login failure
                console.log("Login failed");
                toast.error(response.message);
                setPasswordError(response.parameters.password[0]);
            }



        } catch (error) {
            console.log("Error message:", error.message);
            if (error.message && error.message.status === 400) {
                // If the error is due to a bad request (status 400),
                // access the error message from the response data
                console.log("Error messagesd:", error);
            }
        }
    };


    return (
        <div className="main_body mobile_login">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 sm-order-2">
                        <div className="login_left text-center">
                            <div className='login_logo mb-3'>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className='title_part'>Our Meetings are available on <br></br>these Platforms</div>
                            <div className='login_bg mobile_bg'>
                                <img src={bg} alt="login" className="w-85 mobi_bg" />
                            </div>
                            <div className="copy_right d-sm-block d-md-none d-lg-none text-center t_small_none">
                                Copyright 2024. All Rights Reserved
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <div className="form_container">
                            <div className='login_logo-right mb-3'>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className='login_header-menu mb-3'>
                                <ul>
                                    {location.pathname !== '/admin/login' &&
                                        <div className='login_header-menu mb-3'>
                                            <ul>
                                                <li>
                                                    <Link to="/user/register">Register</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </ul>
                            </div>


                            <div className="login_form-inner">
                                <div className='login_title'>Welcome Back !!!</div>
                                <div className='login_sm-title'>Login to your Account...</div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='input_from position-relative'>
                                        <input
                                            type="email"
                                            placeholder='Enter Email'
                                            // value={email}
                                            onChange={handleChangeEmail}
                                            name="email"
                                            {...register("email", { required: "Email is required" })} />
                                        {errors.email && <span className='error_message'>
                                            {errors.email.message}</span>}
                                        {email == '' && (
                                            <span className='text_close' onClick={clearEmail}>
                                                <img src={closeIcon} alt="close" />
                                            </span>
                                        )}
                                    </div>
                                    <div className='input_from position-relative'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter Password'
                                            name='Password'
                                            {...register("password", { required: "Password is required" })} />
                                        {errors.password && <span className='error_message'>{errors.password.message}</span>}

                                        <span className='text_visible' onClick={togglePasswordVisibility}>
                                            {showPassword ? (
                                                <img src={eyeIcon} alt="hide" />
                                            ) : (
                                                <img src={eyeIcon2} alt="show" />
                                            )}
                                        </span>
                                    </div>
                                    {/* <span className='text-danger'>{passwordError}</span> */}
                                    <div className='forgot_password'>
                                        {isAdminLogin ? (
                                            <Link to="/admin/forgot-password">Recover Password ?</Link>
                                        ) : (
                                            <Link to="/user/forgot-password">Recover Password ?</Link>
                                        )}
                                    </div>
                                    <div>
                                        <button type="submit" className='login_submit btn w-100 text-white'>Sign In</button>

                                    </div>
                                </form>
                            </div>

                            <div className="copy_right text-center my-4 d-none d-sm-none d-md-block d-lg-block">
                                Copyright 2024. All Rights Reserved
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default Login;
