import React, { useEffect, useRef, useState } from 'react';
import './LiveVideos.css'
import videoImg from "../../assets/images/video_thum.png";
import { Link } from 'react-router-dom';
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import { FaCircle } from "react-icons/fa";
import 'bootstrap-datepicker';
import $ from 'jquery';
import '../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { FaPlay } from "react-icons/fa6";
import { LiaTimesSolid } from "react-icons/lia";
import Api from '../Api/Api';
import { IoClose } from "react-icons/io5";
function LiveVideos() {


    const [isVisible, setIsVisible] = useState(false);
    const [getVideoData, setGetVideoData] = useState('');
    const [previousVideoData, setPreviousVideoData] = useState([]);
    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);
    const videoBlogCardRef = useRef(null);
    const [UpcomingHeight, setUpcomingHeight] = useState();
    const [VideoMessage, setVideoMessage] = useState("");
    const [UpcomingVideo, setUpcomingVideo] = useState([]);

    const [isChecked, setIsChecked] = useState(false);
    const { LiveVideoAPI, PreviousMeeting, UpcomingVideoAPI } = Api();
    const [isOpen, setIsOpen] = useState(false);

    const toggleVisibilityMenu = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        $(fromDateRef.current).datepicker();
        $(toDateRef.current).datepicker();
    }, []);



    useEffect(() => {
        GetLiveVideo();
        PreviousVideo();
        UpcomingVideos();
    }, []);


    const GetLiveVideo = async () => {
        try {
            let data = await LiveVideoAPI();
            // setcatalogData(data.categories);
            setGetVideoData(data.parameters);
            setVideoMessage(data)
            console.log("data.parameters", data.parameters);
        } catch (err) {
            console.log(err);
        }
    };

    const PreviousVideo = async () => {
        try {
            let data = await PreviousMeeting();
            // setcatalogData(data.categories);
            setPreviousVideoData(data.parameters.data);
            console.log("data.parameters", data.parameters.data);
        } catch (err) {
            console.log(err);
        }
    };



    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    const handleClose = () => {
        setIsVisible(false);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };




    useEffect(() => {
        if (videoBlogCardRef.current) {
            const height = videoBlogCardRef.current.clientHeight;
            console.log("Height of video_blog-card div:", height);
            setUpcomingHeight(height);
        }
    }, []);




    const UpcomingVideos = async () => {
        try {
            let data = await UpcomingVideoAPI();
            // setcatalogData(data.categories);
            console.log("UpcomingVideoAPI", data.parameters.data);
            setUpcomingVideo(data.parameters.data);
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div className='page_container'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page_title">
                            Live Videos
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-sm-12 col-md-9 mb-3">
                        <div className="video_blog-card" ref={videoBlogCardRef}>


                            <>
                                <div className="live_video-banner mb-3 position-relative">
                                    <img src={getVideoData.cover_link || videoImg} alt={getVideoData.video_title} />
                                </div>
                                <div className="video_description mb-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <Link to={`/user/meeting-detail/${getVideoData.id}`} className='text-decoration-none'>
                                            <div className="live_video-title">
                                                {getVideoData.video_title}
                                            </div>
                                        </Link>
                                        <div className="live_video-section">
                                            {getVideoData.video_description}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='video_social-media'>
                                            <ul className='action_list'>
                                                <li>
                                                    <Link to={getVideoData.youtube_link} target='_blank'>
                                                        <img src={playIcon} alt="play" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={getVideoData.facebook_link} target='_blank'>
                                                        <img src={fbIcon} alt="facebook" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={getVideoData.twitter_link} target='_blank'>
                                                        <img src={twitterIcon} alt="twitter" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="video_other d-flex justify-content-between align-items-center">
                                    <div className='video_status d-flex align-items-center'><FaCircle className='me-2' />{VideoMessage.message}</div>
                                </div>
                            </>




                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 mb-3">
                        <div className="page_title pb-3">
                            Next Upcoming Session
                        </div>

                        <div className='upcoming_session-card slimscroll' style={{ height: `${UpcomingHeight}px` }}>

                            {UpcomingVideo && Array.isArray(UpcomingVideo) && UpcomingVideo.map((data, index) => (
                                <div className="video_blog-card p-3 mb-3" key={index}>
                                    <Link to={`/user/meeting-detail/${data.id}`} className='text-decoration-none'>
                                        <div className="video_banner mb-3 position-relative">
                                            <img src={ data.cover_link || videoImg} alt={data.video_title} />
                                        </div>
                                        <div className="video_description mb-3">
                                            <div className="video_title">{data.video_title}</div>
                                            <div className="video_duration">2 Hours 15 mins</div>
                                        </div>
                                        <div className="video_other">
                                            <div className="btn custom_btn-success">
                                                {data.from_time.substring(0, 5)} - {data.to_time.substring(0, 5)}
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="previous_video-card">
                            {/* <h3 className='mb-3'>Previous Session Videos</h3>
                            <div className="mobile_filter_icon classes mob_fills_icon" onClick={toggleVisibility}>
                                <img src={filter_icon} alt="filter" />
                            </div> */}


                            <div className="row">
                                <div className="col-12">
                                    <div className="mob_fil_align">
                                        <h3 className="page_title">
                                            Previous Session Videos
                                        </h3>
                                        <div className="mobile_filter_icon mob_fills_icon z-index-3" onClick={toggleVisibilityMenu}>
                                            <img src={filter_icon} alt="filter" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row desktop_filter">
                                <div className="col-12">
                                    <form>
                                        <div className={`filter_container ${isOpen ? 'open' : ''}`}>
                                            <div className="filter_icon filter_col sm_flex-end" onClick={toggleVisibilityMenu}>
                                                <img src={filter_icon} alt="filter" className='hide_mobile' />
                                                <IoClose className='show_mobile filter_close' />
                                            </div>
                                            <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
                                                Filter By
                                            </div>
                                            <div className="status_filter filter_col">
                                                <select className="form-control" id="status" name="status" >
                                                    <option value="">Status</option>
                                                    <option value="Upcoming">Upcoming</option>
                                                    <option value="Completed">Completed</option>
                                                    <option value="In-progress">In-progress</option>
                                                </select>
                                            </div>
                                            <div className="from_date-filter filter_col">
                                                <input
                                                    ref={fromDateRef}
                                                    type="text"
                                                    className="form-control border-0"
                                                    placeholder="From Date"
                                                    name='FromDate'

                                                />
                                            </div>
                                            <div className="to_date-filter filter_col">
                                                <input
                                                    ref={toDateRef}
                                                    type="text"
                                                    className="form-control border-0"
                                                    placeholder="To Date"
                                                    name='ToDate'

                                                />
                                            </div>
                                            <div className="from_time-filter filter_col">
                                                <input type="time" name='FromTime' className="form-control" />
                                            </div>
                                            <div className="to_time-filter filter_col">
                                                <input type="time" name='ToTime' className="form-control" />
                                            </div>
                                            <div className="reset_filter filter_col d-flex align-items-center justify-content-center" >
                                                <img src={reload_icon} alt="reset" className="me-2" /> Reset Filter
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>



                            <div className="previous_video-list">
                                <Swiper
                                    slidesPerView={6}
                                    spaceBetween={30}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1
                                        },
                                        768: {
                                            slidesPerView: 2
                                        },
                                        769: {
                                            slidesPerView: 6
                                        }
                                    }}
                                    className="swiper_card"
                                >


                                    {previousVideoData && Array.isArray(previousVideoData) && previousVideoData.map((data, index) => (
                                        <SwiperSlide key={index}>
                                            <Link to={`/user/meeting-detail/${data.id}`} className='text-decoration-none'>
                                                <div className="video_blog-card p-2">
                                                    <div className="video_banner mb-2 position-relative">
                                                        <img src={data.cover_link || videoImg} alt={data.video_title} />
                                                        <div className='previous_video-play'>
                                                            <span><FaPlay /></span>
                                                        </div>
                                                    </div>
                                                    <div className="video_description">
                                                        <div className="video_title">
                                                            {data.video_title}
                                                        </div>
                                                        <div className="video_duration">2 Hours 15 Mins</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    ))}


                                </Swiper>
                            </div>


                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default LiveVideos
